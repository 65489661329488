import React from 'react';

import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createStore } from 'redux';
import allReducer from './reducer/index';
import { Provider } from 'react-redux';
// import reportWebVitals from './reportWebVitals';
// import { MoralisProvider } from "react-moralis";

const store = createStore(allReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION__()
  )

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      {/* <MoralisProvider appId="IC6cVEee1GFwW6sK5Uv0Tzax5Pbr52rjwsnzsZ3Q" serverUrl="https://nxer9smxmt03.usemoralis.com:2053/server"> */}
      <App />
      {/* </MoralisProvider> */}
    </React.StrictMode>,
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
