import Header from "../Components/Header";
import "../Styles/product.css";
import cart_card from "./check.gif";
import eth from "../assets/images/eth.png";
import { ethers } from "ethers";
import Web3 from "web3";
import * as React from "react";
import { useEffect } from "react";
import nftabi from "./nft.json";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { useFormik } from "formik";
import * as yup from 'yup';
import { useState, useRef } from "react";
import profile_large_image from "../assets/images/profile-large-image.png";
import share from "../assets/images/share.png";
import settings from "../assets/images/settings.png";
import cart_items from "../assets/images/cart_card.png";
import '../Styles/offerMade.css';
import sortby from "../../src/assets/images/sortby.png";
import offerUpArrow from "../../src/assets/images/offer-up-arrow.png";
// import Select from "react-select";
import Table from 'react-bootstrap/Table';
let web3 = new Web3(window.ethereum);

function Profile() {
  const [show, setShow] = useState(false);
  let isLogedin = localStorage.getItem("isLogedin");
  let token = localStorage.getItem("token");
  const [imageuri, setimage] = React.useState(cart_card);
  const Nft = new web3.eth.Contract(
    nftabi,
    process.env.REACT_APP_Contract_Address
  );
  const navigate = useNavigate();
  const success = (value) => {
    toast.success(value, { theme: "colored" });
  };
  const faild = (value) => {
    toast.error(value, { theme: "colored" });
  };
  const createhandleShow = () => setShow(true);
  const createhandleClose = () => setShow(false);
  const [_acc, setAcc] = React.useState();
  const [minted, setminted] = React.useState(false);
  const show_collected = () => {
    navigate("/collected-nft");
  }
  let location = window.location.href;
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmpassword: '',
      terms: false,
      notify: false
    },
    validationSchema: yup.object({
      email: yup.string().email("Email must be valid email").required("Email is required"),
      password: yup.string().required("Password is required").min(8, 'Password is too short - should be 8 chars minimum.').matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
      confirmpassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
      terms: yup.boolean().oneOf([true], "You must accept the pricing policy terms and conditions"),
      notify: yup.boolean().oneOf([true], "You must accept the pricing policy terms and conditions"),
    }),
    onSubmit: (userinputdata) => {
      let registerdate = {
        email: userinputdata.email,
        password: userinputdata.password,
        confirm_password: userinputdata.confirmpassword,
      }

      axios.post(`${process.env.REACT_APP_Backend_url}/auth/register`, registerdate).then(res => {
        if (res.data.message === "Registration done successfully") {
          success(res.data.message);
          createhandleClose();
        } else {
          faild(res.data.message);
        }
      });

    }
  });
  var url = new URL(location);
  var id = url.searchParams.get("id");
  console.log(id);
  useEffect(() => {
    async function dataaa() {
      await loadWeb3();
      let accounts = await window.web3.eth.getAccounts();
      axios
        .get(
          `${process.env.REACT_APP_Backend_url}/auth/get-buynft?token_id=${id}`,
          {
            headers: { "x-access-token": `${token}` },
          }
        )
        .then(async (res) => {
          console.log(res.data?.data._userDetail[0]?.Nft_data[0]?.image);
          if (res.data?.data._userDetail[0]?.Nft_data[0]?.image) {
            setimage(res.data?.data._userDetail[0]?.Nft_data[0]?.image);
            setminted(true);
          }
        });
      setAcc(accounts[0]);
      console.log(accounts[0]);
    }
    dataaa();
  }, []);

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  };

  const buyNft = async () => {
    await loadWeb3();
    const accounts = await window.web3.eth.getAccounts();

    console.log(accounts[0]);

    try {
      let name = await Nft.methods.name().call();
      console.log(name);
      let price1 = "0.02";
      //   const price1 = ethers.utils.parseUnits(newPrice.toString(), "ether");
      console.log(id);

      await Nft.methods
        .BuyNFT(process.env.REACT_APP_Nft_address, accounts[0], id)
        .send({
          from: accounts[0],
          value: Web3.utils.toWei(price1, "ether"),
          gas: 100000,
        })
        .then(async (res) => {
          console.log(res);
          let tokenidaddress = await Nft.methods.ownerOf(id).call();
          console.log(tokenidaddress);
          let getUriBytokenid = await Nft.methods.tokenURI(id).call();
          console.log(getUriBytokenid);

          const response = await fetch(getUriBytokenid);

          if (!response.ok) throw new Error(response.statusText);

          const json = await response.json();
          console.log(json);
          let login_data = {
            image: json.image,
            description: json.image,
            token_id: id,
          };
          axios
            .post(`${process.env.REACT_APP_Backend_url}/auth/buynft`, login_data, {
              headers: { "x-access-token": `${token}` },
            })
            .then(async (res) => {
              if (res.data.message === "mint successfully") {
                success(res.data.message);
              }
            });
          setimage(json.image);
          console.log(json.image);
        });
    } catch (err) {
      console.log(err);
    }
    // let gettokenidByaddress = await Nft.methods.gettokenidByaddress("0x9bbc39819A0a1cE456Bd0fE59A37d2C60Ab8143F").call();


  };

  return (
    <>
      <Header active="" />
      <main>
        <div className="container padding-top30px offerMade">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-3">
                  <h4 className="profile-text">My Profile</h4>
                  <div className="profile-image">
                    <img src={profile_large_image} />
                  </div>

                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="d-flex">
                      <div className="row">
                        <div className="col-lg-6">
                          <h2 className="profile-img-text">Vikinglegends</h2>

                        </div>
                        <div className="col-lg-6">
                          <div className="d-flex address-section">
                            <img src={eth} />
                            <h3 className="address ">0x5B0E1D49eeC03c4E62a04dd85e6872e12eEDF611</h3>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                  <div className="col-lg-6 profile-share-settings">
                    <div className="d-flex justify-content-end">
                      <img className="share" src={share} />
                      <img className="setting" src={settings} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">

            </div>
          </div>
          <div className="profile-tab-section">
            <div className="row">
              <div className="col-lg-12 padding-left-0">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link " id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Collected</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Favourites</button>
                  </li>
                  {/* <li class="nav-item" role="presentation">
                    <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">More <i className="fa fa-angle-down"></i></button>
                  </li> */}
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle active" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">More</a>
                    <ul class="dropdown-menu">
                      <li><Link to="/offermode" class="dropdown-item" >Offers Made</Link></li>
                      <li><Link to="/offerrecived" class="dropdown-item" href="#">Offers Received</Link></li>
                    </ul>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    {/* <div className="offset-lg-6">
                      <div className="">
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-7">
                              <div className="input-group justify-content-end search-section">
                                <input className="searchInput" name="" />
                                <label className="input-group-text search" htmlFor="">
                                  <i className="fa fa-search"></i>
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-5">
                              <div className="input-group justify-content-end">
                                <label className="input-group-text sort" >
                                  <img width={15} height={15} src={sortby} />
                                </label>
                                <select className="sort-select">
                                  <option>Sort</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 col-xs-12 offset-xs-2 padding-left-0">
                          <div className="offerMade">
                            <div className="d-flex">
                              <img width={12} height={12} className="m-2" src={offerUpArrow} />
                              <h4>Offers made</h4>
                            </div>
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th width="300">Offer</th>
                                  <th width="150">Unit Price</th>
                                  <th width="150">USD Unit Price</th>
                                  <th width="150" >Floor Difference</th>
                                  <th width="150" >To</th>
                                  <th width="150">Expiration</th>
                                  <th width="150">Sent</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td colSpan={7} className="no-data" >No Data</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">...</div>
                  <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

    </>
  );
}
export default Profile;
