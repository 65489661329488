const initialValues = {
    connected: JSON.parse(localStorage.getItem("wallet_connected")),
    address: ""
}

const isloged = (value = initialValues, action) => {
    switch (action.type) {
        case 'wallet-login':
            return {
                connected :true,
                address:value.address
            };
        default:
            return initialValues

    }

}

export default isloged;