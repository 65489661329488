import Header from "../Components/Header";
import "../Styles/product.css";
import cart_card from "./check.gif";
import eth from "../assets/images/eth.png";
import { ethers } from "ethers";
import Web3 from "web3";
import * as React from "react";
import { useEffect } from "react";
import nftabi from "./nft.json";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import { useState, useRef } from "react";
import Swal from "sweetalert2";
import swal from "sweetalert";
import marketabi from "./marketabi.json";
// import Select from "react-select";
let web3 = new Web3(window.ethereum);

function Prodcut() {
  const [show, setShow] = useState(false);
  let isLogedin = localStorage.getItem("isLogedin");
  let token = localStorage.getItem("token");
  const [imageuri, setimage] = React.useState(cart_card);
  const [tokendata, settokendata] = React.useState([]);

  const Nft = new web3.eth.Contract(
    nftabi,
    process.env.REACT_APP_Nft_address
  );
  let loginConnection = localStorage.getItem("login-connection");
  let address_ = localStorage.getItem("address");
  const navigate = useNavigate();
  const success = (value) => {
    toast.success(value, { theme: "colored" });
  };
  const faild = (value) => {
    toast.error(value, { theme: "colored" });
  };
  const createhandleShow = () => setShow(true);
  const createhandleClose = () => setShow(false);
  const [_acc, setAcc] = React.useState();
  const [wallnew, setwalletnew] = React.useState();
  const [minted, setminted] = React.useState(false);
  let location = window.location.href;
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmpassword: "",
      terms: false,
      notify: false,
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Email must be valid email")
        .required("Email is required"),
      password: yup
        .string()
        .required("Password is required")
        .min(8, "Password is too short - should be 8 chars minimum.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
      confirmpassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match"),
      terms: yup
        .boolean()
        .oneOf(
          [true],
          "You must accept the pricing policy terms and conditions"
        ),
      notify: yup
        .boolean()
        .oneOf(
          [true],
          "You must accept the pricing policy terms and conditions"
        ),
    }),
    onSubmit: (userinputdata) => {
      let registerdate = {
        email: userinputdata.email,
        password: userinputdata.password,
        confirm_password: userinputdata.confirmpassword,
      };

      axios
        .post(
          `${process.env.REACT_APP_Backend_url}/auth/register`,
          registerdate
        )
        .then((res) => {
          if (res.data.message === "Registration done successfully") {
            success(res.data.message);
            createhandleClose();
          } else {
            faild(res.data.message);
          }
        });
    },
  });
  var url = new URL(location);
  var id = url.searchParams.get("id");
  console.log(id);
  useEffect(() => {
    async function dataaa() {
      await loadWeb3();
      let accounts = await window.web3.eth.getAccounts();

      axios
        .get(
          `${process.env.REACT_APP_Backend_url}/auth/get-productnft?token_id=${id}`,
          {
            headers: { "x-access-token": `${token}` },
          }
        )
        .then(async (res) => {
          settokendata(res.data?.data?._userDetail[0]);
          console.log(res.data?.data?._userDetail[0]);
          if (res.data?.data?._userDetail[0]?.image) {
            setimage(res.data?.data?._userDetail[0]?.image);
            setminted(true);
          }
        });
      setAcc(accounts[0]);
      console.log(accounts[0]);

      await loadWeb3();
      let accounts_new = await window.web3.eth.getAccounts();
      axios
        .get(`${process.env.REACT_APP_Backend_url}/auth/get-all-buynft`, {
          headers: { "x-access-token": `${token}` },
        })
        .then(async (res) => {
          console.log(
            "res-",
            res.data?.data?._userDetail[0].wallet_details.address
          );
          // console.log("res-",res.data?.data?._userDetail[0]);
          if (res.data?.data?._userDetail[0]?.wallet_details) {
            console.log(
              "rrr-",
              res.data?.data._userDetail[0]?.wallet_details.address
            );
            setwalletnew(res.data?.data._userDetail[0]?.wallet_details.address);
            // setminted(true);
          } else if (res.data?.data?._userDetail[0]) {
          }
        });
    }
    dataaa();
  }, []);

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  };

  const buyNft = async () => {
    // let loginConnection = localStorage.getItem("login-connection");
    let isLogedin = localStorage.getItem("isLogedin");
    if ((isLogedin === null || !isLogedin )) {
      faild("Please Login")
    }else if(!loginConnection){
      faild("Please Connect Your Wallet")
    }
    
    
    else {
    await loadWeb3();
    const accounts = await window.web3.eth.getAccounts();
    if ( address_ &&  accounts[0] === web3.utils.toChecksumAddress(address_)){

    
      console.log(accounts[0]);

    try {
      console.log(Nft.methods);
      let name = await Nft.methods.name().call();
      console.log(name);
      let nftPrice = await Nft.methods.pricefortokenid(id).call();
      console.log("nftPrice -", nftPrice);
      let contractOwner = await Nft.methods.owner().call();
      const buy_price = web3.utils.fromWei(nftPrice.toString(), "ether");
      console.log(buy_price);
      console.log(id);

      await Nft.methods
        .BuyNFT(contractOwner, accounts[0], id)
        .send({
          from: accounts[0],
          value: Web3.utils.toWei(String(buy_price), "ether"),
          gas: 100000,
        })
        .on("transactionHash", async (res) => {
          console.log(res);
          let tokenidaddress = await Nft.methods.ownerOf(id).call();
          console.log(tokenidaddress);
          let getUriBytokenid = await Nft.methods.tokenURI(id).call();
          console.log(getUriBytokenid);

          // const response = await fetch(getUriBytokenid);

          // if (!response.ok) throw new Error(response.statusText);

          // const json = await response.json();
          // console.log(json);
          let login_data = {
            buy_price: buy_price,
            wallet_address: accounts[0],
            // image: json.image,
            // description: json.image,
            token_id: id,
            buy_transaction_id: res,
          };
          axios
            .post(
              `${process.env.REACT_APP_Backend_url}/auth/buynft`,
              login_data,
              {
                headers: { "x-access-token": `${token}` },
              }
            )
            .then(async (res) => {
              if (res.data.message === "Bought successfully") {
                navigate("/Shop")
                success(res.data.message);
              }
            });
          // setimage(json.image);
          // console.log(json.image);
        });
    } catch (err) {
      console.log(err);
    }
  }else{
    faild("Wallet address not match")
  }
}
    // let gettokenidByaddress = await Nft.methods.gettokenidByaddress("0x9bbc39819A0a1cE456Bd0fE59A37d2C60Ab8143F").call();
  };

  const backUrl = () => {
    navigate(-1);
  };

  return (
    <>
      <Header active="product" />
      <main>
        <div className="container padding-top30px">
          <div className="col-lg-2 mb-2">
            <button className="back-button" onClick={() => backUrl()}>
              {" "}
              <i className="fa fa-arrow-left"></i>&nbsp;&nbsp; Back{" "}
            </button>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <div className="details-page mt-2">
                <div className="row">
                  <div className="col-lg-6">
                    {tokendata.status === "Sell" ? (
                      <img className="product-page-img" src={imageuri} />
                    ) : tokendata.status === "Minted" ? (
                      <img className="product-page-img" src={cart_card} />
                    ) : tokendata.status === "Sold" ? (
                      <img className="product-page-img" src={cart_card} />
                    ) : null}

                    {/* <div className="row">
                      <div className="col-lg-8 col-md-8">
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="d-flex views">
                              <i className="fa fa-eye" aria-hidden="true"></i>
                              <p>13 Views</p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="d-flex views">
                              <i className="fa fa-heart"></i>
                              <p>2 Likes</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <div className="d-flex views">
                          <i className="fa fa-share-alt"></i>
                          <p>Share</p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="col-lg-6 padding-start">
                    <table cellPadding="0">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>breed count</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{tokendata.name}</td>
                          <td>{tokendata.token_id}</td>
                        </tr>
                      </tbody>
                    </table>
                    <h3 className="product-description">overview</h3>
                    <p className="product-description-details">
                      {tokendata.description}
                    </p>
                    <h3 className="product-description">Price</h3>
                    <div className="d-flex">
                      <img className="eth-image" src={eth} />
                      <h1 className="cart-price">{tokendata.price}</h1>
                    </div>

                    <div className="mt-4">

                      {tokendata.Revealed === false ||
                      (tokendata.owner !==
                        web3.utils.toChecksumAddress(wallnew) &&
                        tokendata.status === "Sell") ? (
                        <>
                          {/* {tokendata.status === "Sell" &&
                          tokendata.owner !==
                            web3.utils.toChecksumAddress(wallnew) ? ( */}
                          <button className="but-nft-button" onClick={buyNft}>
                            Buy NFT
                          </button>
                          {/* ) : null} */}
                        </>
                      ) : null}
                      {/* //   <>
                    //   {tokendata.owner  === wallnew && minted ?  (
                    //     <>
                    //     <button className="but-nft-button" onClick={buyNft}>
                    //     Buy New
                    //   </button>
                    //     </>
                    //   ) : null}
                    // </> */}
                      {/* } */}

                      {/* <button
                        className="place-bid-button"
                        onClick={createhandleShow}
                      >
                        Place Bid
                      </button> */}
                    </div>
                    <div className="">
                      <h3 className="address-show">
                        Owner Address : <span>{tokendata.owner}</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="details-suggestion">
                <div className="row">
                  <div className="col-lg-6">
                    <img src={cart_card} alt="Denim Jeans" />
                  </div>
                  <div className="col-lg-6">
                    <h1 className="cart-name mt-2">Viking #0001</h1>
                    <p className="cart-details">Breed Count : 2</p>
                    <p className="cart-details">Price</p>
                    <div className="d-flex">
                      <img className="eth-image" src={eth} />
                      <h1 className="cart-price">0.02</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="details-suggestion">
                <div className="row">
                  <div className="col-lg-6">
                    <img src={cart_card} alt="Denim Jeans" />
                  </div>
                  <div className="col-lg-6">
                    <h1 className="cart-name mt-2">Viking #0001</h1>
                    <p className="cart-details">Breed Count : 2</p>
                    <p className="cart-details">Price</p>
                    <div className="d-flex">
                      <img className="eth-image" src={eth} />
                      <h1 className="cart-price">0.02</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="details-suggestion">
                <div className="row">
                  <div className="col-lg-6">
                    <img src={cart_card} alt="Denim Jeans" />
                  </div>
                  <div className="col-lg-6">
                    <h1 className="cart-name mt-2">Viking #0001</h1>
                    <p className="cart-details">Breed Count : 2</p>
                    <p className="cart-details">Price</p>
                    <div className="d-flex">
                      <img className="eth-image" src={eth} />
                      <h1 className="cart-price">0.02</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Modal
        show={show}
        onHide={createhandleClose}
        dialogClassName="modal-50w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="text-right">
                  <button
                    className="modal-close-button"
                    onClick={createhandleClose}
                  ></button>
                </div>
                {/* <div className="horizontal-line"> */}
                <form
                  className="register-form d-grid gap-3"
                  onSubmit={formik.handleSubmit}
                >
                  <h2 className="register-title">Place Bid</h2>
                  <div className="row">
                    <div className="col-lg-6">
                      <h3 className="place-subtitle">Bidding Amount</h3>
                    </div>
                    <div className="col-lg-6">
                      <h3 className="place-subtitle balance text-right">
                        Balance: 0.00000ETH
                      </h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 ">
                      <select className="filter-select">
                        <option>
                          <img src={eth} />
                          ETH
                        </option>
                        <option>
                          <img src={eth} />
                          BNB
                        </option>
                      </select>
                    </div>
                    <div className="col-lg-8">
                      <div className="place-bid ml-1">
                        <input
                          className="input-field"
                          type="email"
                          placeholder="Amount"
                          name="email"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <h3 className="place-subtitle">Bid expiration</h3>
                    </div>
                    {/* <div className="col-lg-6">                    
                      <h3 className="place-subtitle balance text-right">Balance: 0.00000ETH</h3>
                    </div> */}
                  </div>
                  <div className="row mt-10">
                    <div className="col-lg-4">
                      <select className="filter-select">
                        <option>1 Days</option>
                        <option>2 Days</option>
                        <option>3 Days</option>
                        <option>4 Days</option>
                        <option>5 Days</option>
                      </select>
                    </div>
                    <div className="col-lg-8">
                      <div className="place-bid ">
                        <input
                          className="input-field"
                          type="date"
                          placeholder="Amount"
                          name="date"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mb-3 form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="exampleCheck1"
                    />
                    <label class="form-check-label" for="exampleCheck1">
                      By checking this box, you accept the{" "}
                      <span>privacy policy</span>
                      and <span>Terms of Service.</span>
                    </label>
                  </div>
                  <center>
                    <button className="btn btn-primary place-bid" type="button">
                      Place-bid
                    </button>
                  </center>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Prodcut;
