import Header from "../Components/Header";
import "../Styles/product.css";
import cart_card from "./check.gif";
import eth from "../assets/images/eth.png";
import { ethers } from "ethers";
import Web3 from "web3";
import * as React from "react";
import { useEffect } from "react";
import nftabi from "./nft.json";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import { useState, useRef } from "react";
import profile_large_image from "../assets/images/profile-large-image.png";
import share from "../assets/images/share.png";
import settings_img from "../assets/images/settings.png";
import cart_items from "../assets/images/cart_card.png";
import "../Styles/profile.css";
import sortby from "../../src/assets/images/sortby.png";
import Table from "react-bootstrap/Table";
import offerUpArrow from "../../src/assets/images/offer-up-arrow.png";
// import Select from "react-select";
let web3 = new Web3(window.ethereum);

function Profile() {
  const [show, setShow] = useState(false);
  let isLogedin = localStorage.getItem("isLogedin");
  let token = localStorage.getItem("token");
  const [imageuri, setimage] = React.useState(cart_card);
  const [nftdata, setnftdata] = React.useState({});

  const Nft = new web3.eth.Contract(
    nftabi,
    `${process.env.REACT_APP_Nft_address}`
  );

  const navigate = useNavigate();
  const success = (value) => {
    toast.success(value, { theme: "colored" });
  };
  const faild = (value) => {
    toast.error(value, { theme: "colored" });
  };
  const createhandleShow = () => setShow(true);
  const createhandleClose = () => setShow(false);
  const [_acc, setAcc] = React.useState();
  const [minted, setminted] = React.useState(false);

  const show_collected = (id) => {    
    navigate(`/sell-market-nft?id=${id}`);

  };
  let location = window.location.href;
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmpassword: "",
      terms: false,
      notify: false,
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Email must be valid email")
        .required("Email is required"),
      password: yup
        .string()
        .required("Password is required")
        .min(8, "Password is too short - should be 8 chars minimum.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
      confirmpassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match"),
      terms: yup
        .boolean()
        .oneOf(
          [true],
          "You must accept the pricing policy terms and conditions"
        ),
      notify: yup
        .boolean()
        .oneOf(
          [true],
          "You must accept the pricing policy terms and conditions"
        ),
    }),
    onSubmit: (userinputdata) => {
      let registerdate = {
        email: userinputdata.email,
        password: userinputdata.password,
        confirm_password: userinputdata.confirmpassword,
      };

      axios
        .post(
          `${process.env.REACT_APP_Backend_url}/auth/register`,
          registerdate
        )
        .then((res) => {
          if (res.data.message === "Registration done successfully") {
            success(res.data.message);
            createhandleClose();
          } else {
            faild(res.data.message);
          }
        });
    },
  });
  var url = new URL(location);
  var id = url.searchParams.get("id");
  console.log(id);
  useEffect(() => {
    async function dataaa() {
      await loadWeb3();
      let accounts = await window.web3.eth.getAccounts();
      axios
        .get(`${process.env.REACT_APP_Backend_url}/auth/get-all-buynft`, {
          headers: { "x-access-token": `${token}` },
        })
        .then(async (res) => {
          console.log(res.data?.data._userDetail[0]?.Nft_data);
          if (res.data?.data._userDetail[0]?.Nft_data) {
            setnftdata(res.data?.data._userDetail[0]?.Nft_data);
            setminted(true);
          }
        });
      setAcc(accounts[0]);
      console.log(accounts[0]);
    }
    dataaa();
  }, []);

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  };

  const settings = () => {
    navigate("/settings");
  };
  return (
    <>
      <Header active="" />
      <main>
        <div className="container padding-top30px">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-3">
                  <h4 className="profile-text">My Profile</h4>
                  <div className="profile-image">
                    <img src={profile_large_image} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-6">
                        <h2 className="profile-img-text">Vikinglegends</h2>
                      </div>
                      <div className="col-lg-6">
                        <div className="d-flex address-section">
                          <img src={eth} />
                          <h3 className="address ">
                            0x5B0E1D49eeC03c4E62a04dd85e6872e12eEDF611
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 profile-share-settings">
                    <div className="d-flex justify-content-end">
                      {/* <img className="share" src={share} /> */}
                      <img
                        className="setting"
                        onClick={() => settings()}
                        src={settings_img}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4"></div>
          </div>
          <div className="profile-tab-section">
            <div className="row">
              <div className="col-lg-12 padding-left-0">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Collected
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="offermode-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#offermode"
                      type="button"
                      role="tab"
                      aria-controls="offermode"
                      aria-selected="false"
                    >
                      Offers Made
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="offerreceived-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#offerreceived"
                      type="button"
                      role="tab"
                      aria-controls="offerreceived"
                      aria-selected="false"
                    >
                      Offers Received
                    </button>
                  </li>
                  {/* <li class="nav-item" role="presentation">
                    <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">More <i className="fa fa-angle-down"></i></button>
                  </li> */}
                  {/* <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">More</a>
                    <ul class="dropdown-menu">
                      <li><Link to="/offermode" class="dropdown-item" >Offers Made</Link></li>
                      <li><Link to="/offerrecived" class="dropdown-item" href="#">Offers Received</Link></li>
                    </ul>
                  </li> */}
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="offset-lg-6">
                      <div className="">
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-7">
                              <div className="input-group justify-content-end search-section">
                                <input className="searchInput" name="" />
                                <label
                                  className="input-group-text search"
                                  htmlFor=""
                                >
                                  <i className="fa fa-search"></i>
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-5">
                              <div className="input-group justify-content-end">
                                <label className="input-group-text sort">
                                  <img width={15} height={15} src={sortby} />
                                </label>
                                <select className="sort-select">
                                  <option>Sort</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 col-xs-12 offset-xs-2 padding-left-0">
                        {nftdata?.length > 0 &&
                            nftdata?.map((card, index) => {
                              return (
                          <>
                          <div
                            className="card"
                            onClick={() => show_collected(card.token_id)}
                          >
                            <img src={card.image} alt="Denim Jeans" />
                            <h1 className="cart-name">{card.name}</h1>
                            <p className="cart-details">
                              TokenID:{card.token_id}
                            </p>
                            <p className="cart-details">Price</p>
                            <div className="d-flex">
                              <img className="eth-image" src={eth} />
                              <h1 className="cart-price">0.02</h1>
                            </div>
                          </div>
                          </>
                          );
                        })}

                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="offermode"
                    role="tabpanel"
                    aria-labelledby="offermode-tab"
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 col-xs-12 offset-xs-2 padding-left-0">
                          <div className="offerMade">
                            <div className="d-flex">
                              <img
                                width={12}
                                height={12}
                                className="m-2"
                                src={offerUpArrow}
                              />
                              <h4>Offers made</h4>
                            </div>
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th width="300">Offer</th>
                                  <th width="150">Unit Price</th>
                                  <th width="150">USD Unit Price</th>
                                  <th width="150">Floor Difference</th>
                                  <th width="150">To</th>
                                  <th width="150">Expiration</th>
                                  <th width="150">Sent</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td colSpan={7} className="no-data">
                                    No Data
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="offerreceived"
                    role="tabpanel"
                    aria-labelledby="offerreceived-tab"
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 col-xs-12 offset-xs-2 padding-left-0">
                          <div className="offerMade">
                            <div className="d-flex">
                              <img
                                width={12}
                                height={12}
                                className="m-2"
                                src={offerUpArrow}
                              />
                              <h4>Offers Received</h4>
                            </div>
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th width="300">Offer</th>
                                  <th width="150">Unit Price</th>
                                  <th width="150">USD Unit Price</th>
                                  <th width="150">Floor Difference</th>
                                  <th width="150">To</th>
                                  <th width="150">Expiration</th>
                                  <th width="150">Sent</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td colSpan={7} className="no-data">
                                    No Data
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Modal
        show={show}
        onHide={createhandleClose}
        dialogClassName="modal-50w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="text-right">
                  <button
                    className="modal-close-button"
                    onClick={createhandleClose}
                  ></button>
                </div>
                {/* <div className="horizontal-line"> */}
                <form
                  className="register-form d-grid gap-3"
                  onSubmit={formik.handleSubmit}
                >
                  <h2 className="register-title">Place Bid</h2>
                  <div className="row">
                    <div className="col-lg-6">
                      <h3 className="place-subtitle">Bidding Amount</h3>
                    </div>
                    <div className="col-lg-6">
                      <h3 className="place-subtitle balance text-right">
                        Balance: 0.00000ETH
                      </h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 ">
                      <select className="filter-select">
                        <option>
                          <img src={eth} />
                          ETH
                        </option>
                        <option>
                          <img src={eth} />
                          BNB
                        </option>
                      </select>
                    </div>
                    <div className="col-lg-8">
                      <div className="place-bid ml-1">
                        <input
                          className="input-field"
                          type="email"
                          placeholder="Amount"
                          name="email"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <h3 className="place-subtitle">Bid expiration</h3>
                    </div>
                    {/* <div className="col-lg-6">                    
                      <h3 className="place-subtitle balance text-right">Balance: 0.00000ETH</h3>
                    </div> */}
                  </div>
                  <div className="row mt-10">
                    <div className="col-lg-4">
                      <select className="filter-select">
                        <option>1 Days</option>
                        <option>2 Days</option>
                        <option>3 Days</option>
                        <option>4 Days</option>
                        <option>5 Days</option>
                      </select>
                    </div>
                    <div className="col-lg-8">
                      <div className="place-bid ">
                        <input
                          className="input-field"
                          type="date"
                          placeholder="Amount"
                          name="date"
                        />
                      </div>
                    </div>
                  </div>
                  <center>
                    <button className="btn btn-primary place-bid" type="button">
                      Place-bid
                    </button>
                  </center>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Profile;
