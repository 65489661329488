import Header from '../Components/Header';
import '../Styles/product.css';
import cart_card from './check.gif';
import eth from '../assets/images/eth.png';
import { ethers } from 'ethers';
import Web3 from 'web3';
import * as React from 'react';
import { useEffect } from 'react';
import nftabi from './nft.json';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState, useRef } from 'react';
import '../Styles/collected.css';
import price_histroy from '../assets/images/price_histroy.png';
import listing from '../assets/images/listing.png';
import Offers from '../assets/images/offers.png';
import history from '../assets/images/colleted-history.png';
import cart_items from '../assets/images/cart_card.png';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import marketabi from './marketabi.json';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
// Chart.register(CategoryScale);
// import Select from "react-select";
let web3 = new Web3(window.ethereum);

function Sellmarketplace() {
  // var CanvasJS = CanvasJSReact.CanvasJS;
  // var CanvasJSChart = CanvasJSReact.CanvasJSChart;
  const [show, setShow] = useState(false);
  const [showAccept, setShowAccept] = useState(false);
  let isLogedin = localStorage.getItem('isLogedin');
  let address_ = localStorage.getItem('address');
  let token = localStorage.getItem('token');
  const [imageuri, setimage] = React.useState(cart_card);
  const [nftdata, setnftdata] = React.useState({});
  const [nftowner, setnftowner] = React.useState();

  const [nftdetails, setnft] = React.useState({});
  const [SellAuction, setSellAuction] = React.useState({});
  const [nfttokenid, settokenid] = React.useState({});
  const [tokenprice, settokenprice] = React.useState({});
  const [marketid, setmarketid] = React.useState({});
  const [selldata, setselldata] = React.useState();
  const [nftstatus, setnftstatus] = React.useState();
  const [sellauctiondata, setsellauctiondata] = React.useState();
  const [Auctiondata, setAuctiondata] = React.useState({});
  const [Auctionstatus, setAuctionstatus] = React.useState({});

  const [bidderAddress, setbidderAddress] = React.useState([]);
  const [bidderAmt, setbidderAmt] = React.useState([]);
  const [bidtxid, setbidtxid] = React.useState([]);
  const [bidders, setbidders] = React.useState([]);

  const [buydata, setbuydata] = React.useState();
  const [chartdata, setchartdata] = React.useState([]);
  const [chartdatacount, setchartdatacount] = React.useState([]);
  const [MarketSaleId, setMarketSaleId] = React.useState();
  const [wallnew, setwalletnew] = React.useState();
  const Nft = new web3.eth.Contract(
    nftabi,
    '0xd02F92D5f4Cd365070e6B51B40d605c7912d003a'
  );
  const navigate = useNavigate();
  const success = (value) => {
    toast.success(value, { theme: 'colored' });
  };
  const lineChartData = {
    labels: chartdatacount,
    datasets: [
      {
        data: chartdata,
        label: 'Price',
        borderColor: '#02eff7',
        fill: true,
        lineTension: 0.5,
      },
    ],
  };

  const faild = (value) => {
    toast.error(value, { theme: 'colored' });
  };
  const createhandleShow = () => setShow(true);
  const createhandleClose = () => setShow(false);
  const createAccepthandleShow = () => setShowAccept(true);
  const createAccepthandleClose = () => setShowAccept(false);
  const [_acc, setAcc] = React.useState();
  const [bidaddr, setBidaddr] = React.useState();
  const [Bidamt, setBidamt] = React.useState();

  const [bidres, setBidres] = React.useState();
  const [minted, setminted] = React.useState(false);

  let location = window.location.href;
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmpassword: '',
      terms: false,
      notify: false,
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Email must be valid email')
        .required('Email is required'),
      password: yup
        .string()
        .required('Password is required')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
        ),
      confirmpassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
      terms: yup
        .boolean()
        .oneOf(
          [true],
          'You must accept the pricing policy terms and conditions'
        ),
      notify: yup
        .boolean()
        .oneOf(
          [true],
          'You must accept the pricing policy terms and conditions'
        ),
    }),
    onSubmit: (userinputdata) => {
      let registerdate = {
        email: userinputdata.email,
        password: userinputdata.password,
        confirm_password: userinputdata.confirmpassword,
      };

      axios
        .post(
          `${process.env.REACT_APP_Backend_url}/auth/register`,
          registerdate
        )
        .then((res) => {
          if (res.data.message === 'Registration done successfully') {
            success(res.data.message);
            createhandleClose();
          } else {
            faild(res.data.message);
          }
        });
    },
  });
  var url = new URL(location);
  var id = url.searchParams.get('id');
  console.log(id);
  useEffect(() => {
    async function dataaa() {
      await loadWeb3();
      let accounts = await window.web3.eth.getAccounts();
      axios
        .get(
          `${process.env.REACT_APP_Backend_url}/auth/get-buynft?token_id=${id}`,
          {
            headers: { 'x-access-token': `${token}` },
          }
        )
        .then(async (res) => {
          if (res.data?.data) {
            setnftdata(res.data?.data);
            settokenid(res.data?.data.token_id);
            setnftowner(res.data?.data.owner);
            setnftstatus(res.data?.data.status);

            setminted(true);

            let Sale_data = res.data?.data.Sale_data;
            let Buy_data = res.data?.data.Buy_data;
            let sellAuction_data = res.data?.data.sellAuction_data;
            console.log('asdfjkhsdjklfhasjkl', sellAuction_data);
            let Auction_data = res.data?.data.Auction_data;
            setselldata(Sale_data);
            setbuydata(Buy_data);
            setsellauctiondata(sellAuction_data);
            setAuctiondata(Auction_data);
            let count = [];
            let data = [];
            Buy_data.map((card, index) => {
              count.push(index);
              data.push(card.buy_price);
            });
            setchartdata(data);
            setchartdatacount(count);

            // console.log()
            if (res.data?.data.status === 'Sell-Auction') {
              console.log('Sell-Auction');
              console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa', res.data?.data);
              sellAuction_data
                .filter((key) => key.status === 'Active')
                .map((card, index) => {
                  setmarketid(card.marketId);
                  settokenprice(card.sellAuction_price);
                  setMarketSaleId(card._id);
                  setSellAuction(res.data?.data.status);
                  setbidders(card.bidders);
                  setbidderAddress(card.bidders.bidder_address);
                  console.log('Yellowwwwwwwwwwwwwwwwwwww--', card.bidders);
                });
            } else if (res.data?.data.status === 'Auction') {
              console.log('Auction');

              Auction_data.filter((key) => key.status === 'Active').map(
                (card, index) => {
                  // setmarketid(card.marketId);
                  settokenprice(card.Auction_price);
                  setMarketSaleId(card._id);
                  setSellAuction(res.data?.data.status);
                  setbidders(card.bidders);
                }
              );
            } else if (res.data?.data.status === 'Sell') {
              console.log('Sell');
              Sale_data.filter((key) => key.status === 'Active').map(
                (card, index) => {
                  setmarketid(card.marketId);
                  settokenprice(card.sell_price);
                  setMarketSaleId(card._id);
                  setSellAuction(res.data?.data.status);
                }
              );
            }

            // if (res.data?.data.Sale_data.length > 0) {

            // }
          }
        });
      setAcc(accounts[0]);
      console.log(accounts[0]);

      axios
        .get(`${process.env.REACT_APP_Backend_url}/auth/get-all-mintednft`, {
          headers: { 'x-access-token': `${token}` },
        })
        .then(async (res) => {
          // console.log(res.data?.data._userDetail[0]?.Nft_data);
          setnft(res.data?.data?._userDetail);
          let Nft_data = res.data?.data?._userDetail;
          console.log('nft-', res.data?.data?._userDetail);
          Nft_data.map((card, index) => {
            let token_id = card.token_id;
            // let owner = card.owner;
            console.log('t-', token_id);
          });
          // setAcc(accounts[0]);
          // console.log(accounts[0]);
        });
    }
    axios
      .get(`${process.env.REACT_APP_Backend_url}/auth/get-all-buynft`, {
        headers: { 'x-access-token': `${token}` },
      })
      .then(async (res) => {
        console.log(
          'res-',
          res.data?.data?._userDetail[0].wallet_details.address
        );
        // console.log("res-",res.data?.data?._userDetail[0]);
        if (res.data?.data?._userDetail[0]?.wallet_details) {
          console.log(
            'rrr-',
            res.data?.data._userDetail[0]?.wallet_details.address
          );
          setwalletnew(res.data?.data._userDetail[0]?.wallet_details.address);
          // setminted(true);
        } else if (res.data?.data?._userDetail[0]) {
        }
      });
    dataaa();
  }, []);

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        'Non-Ethereum browser detected. You should consider trying MetaMask!'
      );
    }
  };

  const sellCollectedNft = () => {
    navigate(`/sell-collected-nft?id=${id}`);
    // navigate("/sell-collected-nft")
  };
  const backUrl = () => {
    navigate(-1);
  };

  const createMarketSale = async () => {
    await loadWeb3();
    const accounts = await window.web3.eth.getAccounts();

    console.log(accounts[0]);
    const cryptoContract = new web3.eth.Contract(
      marketabi,
      // `${process.env.REACT_APP_Marketplace_Address}`
      '0x3db640C2fE2E9399d366a6CdBB935cb07B46f745'
    );
    console.log(cryptoContract.methods);
    let ownercheck = await Nft.methods.ownerOf(id).call();
    console.log('tokenidTomarketid-', ownercheck);
    try {
      const price1 = web3.utils.toWei(String(tokenprice), 'ether');
      console.log('price1-', price1);
      console.log('marketid-', marketid);
      console.log('id-', id);

      let tokenidTomarketid = await cryptoContract.methods
        .tokenidToMarketid(id)
        .call();
      console.log('tokenidTomarketid-', tokenidTomarketid);

      await cryptoContract.methods
        .createMarketSale(
          // `${process.env.REACT_APP_Nft_address}`,
          '0xd02F92D5f4Cd365070e6B51B40d605c7912d003a',
          String(tokenidTomarketid) // 6 - marketId
        )
        .send({
          from: accounts[0],
          value: price1, // value: 5000000000000000 - working
          gas: 1000000,
        })
        .on('transactionHash', async (res) => {
          console.log(res);
          let postdata = {
            MarketSaleId,
            wallet_address: accounts[0],
            amount: price1,
            token_id: id,
            buy_transaction_id: res,
            buy_price: tokenprice,
            Sell_Auction: SellAuction,
          };
          axios
            .post(
              `${process.env.REACT_APP_Backend_url}/auth/buyNft-createmarketsale`,
              postdata,
              {
                headers: { 'x-access-token': `${token}` },
              }
            )
            .then(async (res) => {
              if (res.data.message === 'sale successfully') {
                success(res.data.message);
              }
            });
          await swal(
            'Success',
            'Sale Completed in Marketplace ' + res,
            'success'
          ).then(
            setTimeout(function () {
              window.location.reload();
            }, 2000)
          );
        });
    } catch (err) {
      console.log(err);
    }
  };

  const placeBid = async () => {
    console.log('bbbbbbbbbbbbbbbbb', bidders);
    console.log('MarketSaleId----', MarketSaleId);

    Swal.fire({
      title: 'Place a Bid',
      html: `<input type="number" id="amount" class="swal2-input" placeholder="Amount">`,
      confirmButtonText: 'Confirm',
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        const amount = Swal.getPopup().querySelector('#amount').value;
        if (!amount) {
          Swal.showValidationMessage(`Please enter amount`);
        }
        return { amount: amount };
      },
    }).then(async (result) => {
      console.log(result.value.amount);
      let price = result.value.amount;
      console.log('price -', price);
      await loadWeb3();
      const accounts = await window.web3.eth.getAccounts();

      console.log(accounts[0]);
      const cryptoContract = new web3.eth.Contract(
        marketabi,
        // `${process.env.REACT_APP_Marketplace_Address}`
        '0x3db640C2fE2E9399d366a6CdBB935cb07B46f745'
      );
      console.log(cryptoContract.methods);

      try {
        const priceInWei = ethers.utils.parseUnits(price.toString(), 'ether');
        console.log('priceWei -', priceInWei);
        console.log('id-', id);

        await cryptoContract.methods
          .bid(
            // `${process.env.REACT_APP_Nft_address}`,
            '0xd02F92D5f4Cd365070e6B51B40d605c7912d003a',
            String(id)
          )
          .send({
            from: accounts[0],
            value: priceInWei,
            gas: 1000000,
          })
          .on('transactionHash', async (res) => {
            console.log(res);
            let postdata = {
              MarketSaleId,
              token_id: id,
              bid_amount: price,
              bidder_address: accounts[0],
              bid_transaction_id: res,
              Sell_Auction: SellAuction,
            };
            console.log('postdata--', postdata);
            axios
              .post(
                `${process.env.REACT_APP_Backend_url}/auth/placeBid`,
                postdata,
                {
                  headers: { 'x-access-token': `${token}` },
                }
              )
              .then(async (res) => {
                if (res.data.message === 'bid successfully') {
                  success(res.data.message);
                }
              });
            await swal(
              'Success',
              'Place bid Completed in Marketplace ' + res,
              'success'
            );
            // .then(
            //   setTimeout(function () {
            //     window.location.reload();
            //   }, 2000)
            // );
          });
      } catch (err) {
        console.log(err);
      }
    });
  };

  const auctionCancel = async () => {
    await loadWeb3();
    const accounts = await window.web3.eth.getAccounts();

    console.log(accounts[0]);
    const cryptoContract = new web3.eth.Contract(
      marketabi,
      // `${process.env.REACT_APP_Marketplace_Address}`
      '0x3db640C2fE2E9399d366a6CdBB935cb07B46f745'
    );
    console.log(cryptoContract.methods);
    let ownercheck = await Nft.methods.ownerOf(id).call();
    console.log('ownercheck-', ownercheck);
    console.log('nftowner', nftowner);

    // if (ownercheck === nftowner) {
    try {
      await cryptoContract.methods
        .cancelAuction('0xd02F92D5f4Cd365070e6B51B40d605c7912d003a', String(id))
        .send({ from: accounts[0], gas: 1000000 })
        .on('transactionHash', async (res) => {
          console.log(res);

          let postdata = {
            MarketSaleId,
            token_id: id,
            SellAuction,
            cancel_auction_transaction_id: res,
          };
          await axios
            .post(
              `${process.env.REACT_APP_Backend_url}/auth/cancel_auction`,
              postdata,
              {
                headers: { 'x-access-token': `${token}` },
              }
            )
            .then(async (res) => {
              if (res.data.message === 'Auction Cancelled successfully') {
                success(res.data.message);
              }
            });
          await swal(
            'Success',
            'Auction Cancelled Successfully ' + res,
            'success'
          ).then(
            setTimeout(function () {
              window.location.reload();
            }, 2000)
          );
        });
    } catch (err) {
      console.log(err);
    }
    // };
  };

  const sellAuctionCancel = async () => {
    await loadWeb3();
    const accounts = await window.web3.eth.getAccounts();

    console.log(accounts[0]);
    const cryptoContract = new web3.eth.Contract(
      marketabi,
      // `${process.env.REACT_APP_Marketplace_Address}`
      '0x3db640C2fE2E9399d366a6CdBB935cb07B46f745'
    );
    console.log(cryptoContract.methods);
    console.log('mmmmmmmmmmmmmmmarket', marketid);

    let tokenidTomarketid = await cryptoContract.methods
      .tokenidToMarketid(id)
      .call();
    console.log('tokenidTomarketid-', tokenidTomarketid);

    const Nft = new web3.eth.Contract(
      nftabi,
      '0xd02F92D5f4Cd365070e6B51B40d605c7912d003a'
    );
    let ownercheck = await Nft.methods.ownerOf(id).call();
    console.log('ownercheck-', ownercheck);
    console.log('nftowner', nftowner);
    // if(ownercheck === nftowner) {
    try {
      await cryptoContract.methods
        .cancelSellAuction(
          '0xd02F92D5f4Cd365070e6B51B40d605c7912d003a',
          String(tokenidTomarketid)
        )
        .send({ from: accounts[0], gas: 1000000 })
        .on('transactionHash', async (res) => {
          console.log(res);

          let postdata = {
            MarketSaleId,
            token_id: id,
            SellAuction,
            cancel_sellauction_transaction_id: res,
          };
          await axios
            .post(
              `${process.env.REACT_APP_Backend_url}/auth/cancel_sellauction`,
              postdata,
              {
                headers: { 'x-access-token': `${token}` },
              }
            )
            .then(async (res) => {
              if (res.data.message === 'sell-Auction Cancelled successfully') {
                success(res.data.message);
              }
            });
          await swal(
            'Success',
            'sell-Auction Cancelled Successfully ' + res,
            'success'
          ).then(
            setTimeout(function () {
              window.location.reload();
            }, 2000)
          );
        });
    } catch (err) {
      console.log(err);
    }
    // }
  };

  const cancelMarketNft = async () => {
    await loadWeb3();
    const accounts = await window.web3.eth.getAccounts();

    console.log(accounts[0]);
    const cryptoContract = new web3.eth.Contract(
      marketabi,
      // `${process.env.REACT_APP_Marketplace_Address}`
      '0x3db640C2fE2E9399d366a6CdBB935cb07B46f745'
    );
    console.log(cryptoContract.methods);
    console.log('mmmmmmmmmmmmmmmarket', marketid);

    let tokenidTomarketid = await cryptoContract.methods
      .tokenidToMarketid(id)
      .call();
    console.log('tokenidTomarketid-', tokenidTomarketid);

    const Nft = new web3.eth.Contract(
      nftabi,
      '0xd02F92D5f4Cd365070e6B51B40d605c7912d003a'
    );
    let ownercheck = await Nft.methods.ownerOf(id).call();
    console.log('ownercheck-', ownercheck);
    console.log('nftowner', nftowner);
    // if(ownercheck === nftowner) {
    try {
      await cryptoContract.methods
        .cancelMarketItem(
          '0xd02F92D5f4Cd365070e6B51B40d605c7912d003a',
          String(tokenidTomarketid)
        )
        .send({ from: accounts[0], gas: 1000000 })
        .on('transactionHash', async (res) => {
          console.log(res);

          let postdata = {
            MarketSaleId,
            token_id: id,
            SellAuction,
            cancel_marketitem_transaction_id: res,
          };
          await axios
            .post(
              `${process.env.REACT_APP_Backend_url}/auth/cancel_marketitem`,
              postdata,
              {
                headers: { 'x-access-token': `${token}` },
              }
            )
            .then(async (res) => {
              if (res.data.message === 'MarketItem Cancelled successfully') {
                success(res.data.message);
              }
            });
          await swal(
            'Success',
            'MarketItem Cancelled Successfully ' + res,
            'success'
          ).then(
            setTimeout(function () {
              window.location.reload();
            }, 2000)
          );
        });
    } catch (err) {
      console.log(err);
    }
    // }
  };

  const executeSale = async () => {
    await loadWeb3();
    const accounts = await window.web3.eth.getAccounts();

    console.log(accounts[0]);
    const cryptoContract = new web3.eth.Contract(
      marketabi,
      // `${process.env.REACT_APP_Marketplace_Address}`
      '0x3db640C2fE2E9399d366a6CdBB935cb07B46f745'
    );
    console.log(cryptoContract.methods);

    const Nft = new web3.eth.Contract(
      nftabi,
      '0xd02F92D5f4Cd365070e6B51B40d605c7912d003a'
    );
    let ownercheck = await Nft.methods.ownerOf(id).call();
    console.log('ownercheck-', ownercheck);
    console.log('nftowner', nftowner);
    console.log('bidaddr', bidaddr);
    // if(ownercheck === nftowner) {
    try {
      await cryptoContract.methods
        .executeSale(
          '0xd02F92D5f4Cd365070e6B51B40d605c7912d003a',
          String(id),
          // "0x5B0E1D49eeC03c4E62a04dd85e6872e12eEDF611"
          bidaddr
        )
        .send({ from: accounts[0], gas: 1000000 })
        .on('transactionHash', async (res) => {
          console.log(res);
          setBidres(res);

          let postdata = {
            MarketSaleId,
            token_id: id,
            Sell_Auction: SellAuction,
            max_bidder_address: bidaddr,
            max_bid_amount: Bidamt,
            bid_sale_transaction_id: res,
          };
          await axios
            .post(
              `${process.env.REACT_APP_Backend_url}/auth/claimBid`,
              postdata,
              {
                headers: { 'x-access-token': `${token}` },
              }
            )
            .then(async (res) => {
              if (res.data.message === ' successfully') {
                success(res.data.message);
              }
            });
          await swal(
            'Success',
            'Offer Claimed done Successfully ' + res,
            'success'
          );
          // .then(
          //   setTimeout(function () {
          //     window.location.reload();
          //   }, 2000)
          // );
        });
    } catch (err) {
      console.log(err);
    }
    // }
  };

  return (
    <>
      <Header active='product' />
      <main>
        <div className='container padding-top30px'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='col-lg-2'>
                <button className='back-button' onClick={() => backUrl()}>
                  {' '}
                  <i className='fa fa-arrow-left'></i> Back{' '}
                </button>
              </div>
              <div className='colleted-details-page'>
                <div className='row'>
                  <div className='col-lg-4 product-section'>
                    <div className='row'>
                      <div className='col-lg-8 col-md-8'>
                        <div className='row'>
                          {/* <div className="col-lg-6 col-md-6">
                            <div className="d-flex views">
                              <i className="fa fa-eye" aria-hidden="true"></i>
                              <p>13 Views</p>
                            </div>
                          </div> */}
                          {/* <div className="col-lg-6 col-md-6">
                            <div className="d-flex views">
                              <i className="fa fa-heart"></i>
                              <p>2 Likes</p>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-4'>
                        {/* <div className="d-flex views">
                          <i className="fa fa-share-alt"></i>
                          <p>Share</p>
                        </div> */}
                      </div>
                    </div>
                    <img
                      className='product-page-img mt-3'
                      src={nftdata.image}
                    />
                  </div>
                  <div className='col-lg-7 padding-start'>
                    <h2 className='nft-name'>Play Fun{nftdata.token_id}</h2>
                    {nftdata.owner ===
                    web3.utils.toChecksumAddress(address_) ? (
                      <>
                        <h3 className='owned-status'>
                          Owned by <span>you</span>
                        </h3>
                      </>
                    ) : null}

                    <div className='d-flex justify-content-start'>
                      {address_ !== null ? (
                        <>
                          {nftdata.status !== 'Sell' &&
                          nftdata.status !== 'Sell-Auction' &&
                          nftdata.status !== 'Auction' &&
                          nftdata.owner ===
                            web3.utils.toChecksumAddress(address_) ? (
                            <>
                              <button
                                className='but-nft-button'
                                onClick={() => sellCollectedNft()}
                              >
                                Sell
                              </button>
                            </>
                          ) : null}

                          {nftdata.status === 'Sell-Auction' &&
                          nftdata.owner !==
                            web3.utils.toChecksumAddress(address_) ? (
                            <>
                              <button
                                className='but-nft-button'
                                onClick={() => createMarketSale()}
                              >
                                Buy Now
                              </button>
                              <button
                                className='but-nft-button'
                                onClick={() => placeBid()}
                              >
                                Place bid
                              </button>
                            </>
                          ) : null}

                          {nftdata.status === 'Sell' &&
                          nftdata.owner ===
                            web3.utils.toChecksumAddress(address_) ? (
                            <>
                              <button
                                className='but-nft-button'
                                // onClick={() => sellCollectedNft()}
                              >
                                Listed
                              </button>
                              <button
                                className='but-nft-button'
                                onClick={() => cancelMarketNft()}
                              >
                                Cancel
                              </button>
                            </>
                          ) : null}

                          {nftdata.status === 'Sell' &&
                          nftdata.owner !==
                            web3.utils.toChecksumAddress(address_) ? (
                            <>
                              <button
                                className='but-nft-button'
                                onClick={() => createMarketSale()}
                              >
                                Buy Now
                              </button>
                            </>
                          ) : null}

                          {nftdata.status === 'Sell-Auction' &&
                          nftdata.owner ===
                            web3.utils.toChecksumAddress(address_) ? (
                            <>
                              <button
                                className='but-nft-button'
                                // onClick={() => sellCollectedNft()}
                              >
                                Listed Sell-Auction
                              </button>
                              <button
                                className='but-nft-button'
                                onClick={() => sellAuctionCancel()}
                              >
                                Cancel
                              </button>
                            </>
                          ) : null}

                          {nftdata.status === 'Auction' &&
                          nftdata.owner ===
                            web3.utils.toChecksumAddress(address_) ? (
                            <>
                              <button
                                className='but-nft-button'
                                // onClick={() => sellCollectedNft()}
                              >
                                Auction Made
                              </button>
                              <button
                                className='but-nft-button'
                                onClick={() => auctionCancel()}
                              >
                                Cancel
                              </button>
                            </>
                          ) : null}
                          {nftdata.status === 'Auction' &&
                          nftdata.owner !==
                            web3.utils.toChecksumAddress(address_) ? (
                            <>
                              <button
                                className='but-nft-button'
                                onClick={() => placeBid()}
                              >
                                Place Bid
                              </button>
                              <h2 className='but-sales'>Sales Ends</h2>
                            </>
                          ) : null}
                        </>
                      ) : (
                        <button
                          className='but-nft-button'
                          // onClick={() => sellCollectedNft()}
                        >
                          Buy Now
                        </button>
                      )}

                      {/* <button className="but-nft-button" onClick={createhandleShow}>Transfer</button> */}
                    </div>
                    {/* <div class="accordion accordion-flush price-history" id="accordionFlushExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            <img width={20} height={20} src={price_histroy} />
                            Price History
                          </button>
                        </h2>
                        <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                          <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.</div>
                        </div>
                      </div>
                    </div> */}
                    <div className='price-section'>
                      <div className='header-section d-flex justify-content-between align-items-center'>
                        <div className='d-flex align-items-center'>
                          <img width={20} height={20} src={price_histroy} />
                          <h3 className='price-section-header'>
                            Price History
                          </h3>
                        </div>
                        <i className='fa fa-angle-up'></i>
                      </div>
                      <div className='price-box-list'>
                        {buydata?.length > 0 ? (
                          <>
                            <Line datasetIdKey='id' data={lineChartData} />
                          </>
                        ) : null}
                        {buydata?.length === 0 ? (
                          <>
                            <p className='no-price'>No Price History</p>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-4 no-padding'>
                    <div className='row'>
                      <div className='over-view-border'>
                        <div className='over-view-section'>
                          <h3 className='product-description'>Overview</h3>
                          <p className='product-description-details'>
                            Play Fun{nftdata.token_id}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div
                        class='accordion accordion-flush offer-price'
                        id='accordionFlushExample'
                      >
                        <div class='accordion-item'>
                          <h2
                            class='accordion-header'
                            id='flush-headingofferone'
                          >
                            <button
                              class='accordion-button collapsed '
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#flush-collapseofferone'
                              aria-expanded='false'
                              aria-controls='flush-collapseofferone'
                            >
                              <img
                                width={20}
                                height={20}
                                className='ml-1'
                                src={Offers}
                              />
                              &nbsp;&nbsp;Details
                            </button>
                          </h2>
                          <div
                            id='flush-collapseofferone'
                            class='accordion-collapse collapse'
                            aria-labelledby='flush-headingofferone'
                            data-bs-parent='#accordionFlushExample'
                          >
                            <div class='accordion-body'>
                              <div className='row'>
                                <div className='col-lg-12'>
                                  <div className='details-section'>
                                    <div className='d-flex justify-content-between'>
                                      <h5>Contract Address</h5>
                                      <p className='colored'>
                                        <a
                                          className='colored'
                                          href='https://testnet.bscscan.com/address/0x3db640C2fE2E9399d366a6CdBB935cb07B46f745'
                                          target='blank'
                                        >
                                          0x3db640C2fE2E9399d366a6CdBB935cb07B46f745
                                        </a>
                                      </p>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                      <h5>Breed Count</h5>
                                      <h5 className='colored'>
                                        {nftdata.token_id}
                                      </h5>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                      <h5>Token Standard</h5>
                                      <h5 className='gray'>BNB</h5>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                      <h5>Blockchain</h5>
                                      <h5 className='gray'>BSC Chain</h5>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                      <h5>Metadata</h5>
                                      <h5 className='colored'>Frozen</h5>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                      <h5>Creator Earnings</h5>
                                      <h5 className='gray'>2.5%</h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-7 listing-details'>
                    <div className='mt-2'>
                      <div
                        class='accordion accordion-flush price-history'
                        id='accordionFlushExample'
                      >
                        <div class='accordion-item'>
                          <h2 class='accordion-header' id='flush-headingOne'>
                            <button
                              class='accordion-button collapsed '
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#flush-collapseOne'
                              aria-expanded='false'
                              aria-controls='flush-collapseOne'
                            >
                              <img
                                width={20}
                                height={20}
                                className='ml-1'
                                src={listing}
                              />
                              &nbsp;&nbsp;Listings
                            </button>
                          </h2>
                          <div
                            id='flush-collapseOne'
                            class='accordion-collapse collapse'
                            aria-labelledby='flush-headingOne'
                            data-bs-parent='#accordionFlushExample'
                          >
                            <div class='accordion-body'>
                              <table cellPadding='0'>
                                <thead>
                                  <tr>
                                    <th>Price</th>
                                    <th>Market breed</th>
                                    <th>Address</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {/* {console.log(selldata)} */}
                                  {nftstatus === 'Sell-Auction' ? (
                                    <>
                                      {sellauctiondata?.length > 0 &&
                                        sellauctiondata.map((card, index) => {
                                          return (
                                            <>
                                              {card.status === 'Active' ? (
                                                <>
                                                  <tr>
                                                    <td>
                                                      <p className='no-padding'>
                                                        {card.sellAuction_price}
                                                      </p>
                                                    </td>
                                                    <td>{nfttokenid}</td>
                                                    <td>
                                                      {' '}
                                                      <p className='address'>
                                                        {card.Address}
                                                      </p>{' '}
                                                    </td>
                                                    <td
                                                      className={
                                                        card.status === 'Active'
                                                          ? 'text-success'
                                                          : 'text-danger'
                                                      }
                                                    >
                                                      {card.status}
                                                    </td>
                                                  </tr>
                                                </>
                                              ) : null}
                                            </>
                                          );
                                        })}
                                    </>
                                  ) : nftstatus === 'Sell' ? (
                                    <>
                                      {selldata?.length > 0 &&
                                        selldata.map((card, index) => {
                                          return (
                                            <>
                                              {card.status === 'Active' ? (
                                                <>
                                                  <tr>
                                                    <td>
                                                      <p className='no-padding'>
                                                        {card.sell_price}
                                                      </p>
                                                    </td>
                                                    <td>{nfttokenid}</td>
                                                    <td>
                                                      {' '}
                                                      <p className='address'>
                                                        {card.Address}
                                                      </p>{' '}
                                                    </td>
                                                    <td
                                                      className={
                                                        card.status === 'Active'
                                                          ? 'text-success'
                                                          : 'text-danger'
                                                      }
                                                    >
                                                      {card.status}
                                                    </td>
                                                  </tr>
                                                </>
                                              ) : null}
                                            </>
                                          );
                                        })}
                                    </>
                                  ) : nftstatus === 'Auction' ? (
                                    <>
                                      {Auctiondata?.length > 0 &&
                                        Auctiondata.map((card, index) => {
                                          return (
                                            <>
                                              {card.status === 'Active' ? (
                                                <>
                                                  <tr>
                                                    <td>
                                                      <p className='no-padding'>
                                                        {card.bid_amount_init}
                                                      </p>
                                                    </td>
                                                    <td>{nfttokenid}</td>
                                                    <td>
                                                      {' '}
                                                      <p className='address'>
                                                        {card.Auction_creator}
                                                      </p>{' '}
                                                    </td>
                                                    <td
                                                      className={
                                                        card.status === 'Active'
                                                          ? 'text-success'
                                                          : 'text-danger'
                                                      }
                                                    >
                                                      {card.status}
                                                    </td>
                                                  </tr>
                                                </>
                                              ) : null}
                                            </>
                                          );
                                        })}
                                    </>
                                  ) : null}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='mt-2'>
                      <div
                        class='accordion accordion-flush price-history'
                        id='accordionFlushExample'
                      >
                        <div class='accordion-item'>
                          <h2
                            class='accordion-header'
                            id='flush-headingoffertwo '
                          >
                            <button
                              class='accordion-button collapsed '
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#flush-collapseoffertwo'
                              aria-expanded='false'
                              aria-controls='flush-collapseoffertwo'
                            >
                              <img
                                width={20}
                                height={20}
                                className='ml-1'
                                src={Offers}
                              />
                              &nbsp;&nbsp;Offers
                            </button>
                          </h2>
                          <div
                            id='flush-collapseoffertwo'
                            class='accordion-collapse collapse'
                            aria-labelledby='flush-headingoffertwo'
                            data-bs-parent='#accordionFlushExample'
                          >
                            <div class='accordion-body'>
                              <table cellPadding='0'>
                                <thead>
                                  <tr>
                                    <th>Address</th>
                                    <th>Price</th>
                                    <th></th>
                                    {/* <th>Expiration</th> */}
                                    {/* <th>From</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {bidders.map((bidd, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{bidd.bidder_address}</td>
                                        <td>{bidd.bid_amount}</td>

                                        <td>
                                          {nftdata.owner ===
                                          web3.utils.toChecksumAddress(
                                            address_
                                          ) ? (
                                            <>
                                              <button
                                                className='accept-button'
                                                onClick={() => {
                                                  createhandleShow();
                                                  setBidaddr(
                                                    bidd.bidder_address
                                                  );
                                                  setBidamt(bidd.bid_amount);
                                                }}
                                              >
                                                Accept&nbsp;
                                                <i className='fa fa-angle-right'></i>
                                              </button>
                                            </>
                                          ) : null}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-lg-4 no-padding">
                    <div className="listing-border">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <img width={20} height={20} src={Offers} />
                          <h3 className="price-section-header">Listings</h3>
                        </div>
                        <i className="fa fa-angle-down"></i>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className='row'>
                  <div className='col-lg-11 no-padding'>
                    <div className='mt-2 mb-4'>
                      <div
                        class='accordion accordion-flush price-history'
                        id='accordionFlushExample'
                      >
                        <div class='accordion-item'>
                          <h2
                            class='accordion-header'
                            id='flush-headingoffertwo '
                          >
                            <button
                              class='accordion-button collapsed '
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#flush-collapseofferthree'
                              aria-expanded='false'
                              aria-controls='flush-collapseofferthree'
                            >
                              <img width={20} height={20} src={history} />
                              &nbsp;&nbsp;Item history
                            </button>
                          </h2>
                          <div
                            id='flush-collapseofferthree'
                            class='accordion-collapse collapse'
                            aria-labelledby='flush-headingoffertwo'
                            data-bs-parent='#accordionFlushExample'
                          >
                            <div class='accordion-body'>
                              <table cellPadding='0'>
                                <thead>
                                  <tr>
                                    <th>Event</th>
                                    <th>Price</th>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {buydata?.length > 0 &&
                                    buydata.map((card, index) => {
                                      return (
                                        <>
                                          <tr>
                                            <td>{card.Event}</td>
                                            <td>{card.buy_price}</td>
                                            <td>
                                              <p className='address'>
                                                {card.From}
                                              </p>
                                            </td>
                                            <td>
                                              <p className='address'>
                                                {card.To}
                                              </p>
                                            </td>
                                            <td>{card.Date}</td>
                                          </tr>
                                        </>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <center>
                  {/* <button className="view-colletion">View Collection</button> */}
                </center>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Modal
        show={show}
        onHide={createhandleClose}
        dialogClassName='modal-50w accept-place-bid'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Modal.Body>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12 '>
                <div className='text-right'>
                  <button
                    className='modal-close-button'
                    onClick={createhandleClose}
                  ></button>
                </div>
                {/* <div className="horizontal-line"> */}
                <form
                  className='register-form d-grid gap-3'
                  onSubmit={formik.handleSubmit}
                >
                  <h2 className='accept-bid-title'>Accept Offer</h2>
                  <div className='row'>
                    <div className='col-lg-7'>
                      <h3 className='accetp-place-subtitle'>Item</h3>
                      <div className='d-flex justify-content-around'>
                        <img
                          style={{ borderRadius: '3.2263px' }}
                          width={99}
                          height={126}
                          src={cart_items}
                        />
                        <div className='image-with-center-text-section'>
                          <div className='image-with-center-text'>
                            <h3 className='accetp-place-subtitle'>
                              {nftdata.name}
                            </h3>

                            <h4 className='accept-own-place-subtitle'>
                              Owned by <span>You</span>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-5'>
                      <h3 className='accetp-place-subtitle-subtotal'>
                        Subtotal
                      </h3>
                      <br />
                      <div className='d-flex float-end justify-content-between'>
                        <img width={25} height={25} src={eth} />
                        &nbsp;&nbsp;
                        <h3 className='accetp-place-subtitle '>0.002</h3>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <br />

                        <div className='d-flex justify-content-between'>
                          <h3 className='accetp-place-subtitle'>Fees</h3>
                          {/* <h3 className="accetp-place-subtitle">0.002</h3> */}
                        </div>

                        <div className='d-flex justify-content-between'>
                          <h2 className='accept-fee-place-subtitle'>
                            Marketplace fee
                          </h2>
                          <h2 className='accept-fee-place-subtitle'>10%</h2>
                        </div>

                        <div className='d-flex justify-content-between'>
                          {/* <h2 className="accept-fee-place-subtitle">
                            Creator Royalty
                          </h2> */}
                          {/* <h2 className="accept-fee-place-subtitle">10%</h2> */}
                        </div>
                        <br />
                        <div className='d-flex justify-content-between'>
                          <h3 className='accetp-place-subtitle'>
                            Total Earnings
                          </h3>
                          <div className='d-flex bid-amount-text justify-content-between'>
                            <img width={25} height={25} src={eth} />
                            &nbsp; 0.001975
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <center>
                      <button
                        className='btn btn-primary accept-place-bid'
                        onClick={() => {
                          createhandleClose();
                          createAccepthandleShow();
                          executeSale();
                        }}
                        type='button'
                      >
                        Accept Goku
                      </button>
                    </center>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showAccept}
        onHide={createAccepthandleShow}
        dialogClassName='modal-50w accept-place-bid'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Modal.Body>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12 '>
                <div className='text-right'>
                  <button
                    className='modal-close-button'
                    onClick={createAccepthandleClose}
                  ></button>
                </div>
                {/* <div className="horizontal-line"> */}
                <form
                  className='register-form d-grid gap-3'
                  onSubmit={formik.handleSubmit}
                >
                  <h2 className='accept-bid-title'>Your sale is processing!</h2>
                  <h3 className='transaction-accept-bid-title'>
                    Success!You just sold Ether. It will be confirmed on
                    blockchain Shortly.
                  </h3>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <center>
                        <img width={150} height={150} src={cart_items} />
                      </center>
                    </div>

                    <div className='col-lg-8 offset-lg-2'>
                      <div className='transfered-status'>
                        <br />
                        <br />
                        <table cellPadding='0'>
                          <thead>
                            <tr>
                              <th>Status</th>
                              <th>Transaction Hash</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Processing</td>
                              <td className='transaction-address bidres-class'>
                                {bidres}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Sellmarketplace;
