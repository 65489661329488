import React from 'react';
import NFT1 from '../../ArtAssets/nft1.jpg';
import NFT2 from '../../ArtAssets/nft2.png';
import NFT3 from '../../ArtAssets/nft3.jpg';
import caraousel1 from '../../ArtAssets/caraousel1.jpg';
import caraousel2 from '../../ArtAssets/caraousel2.jpg';
import caraousel3 from '../../ArtAssets/caraousel3.jpg';

const ArtImages = [
  { id: 1, image: NFT1 },
  { id: 2, image: NFT2 },
  { id: 3, image: NFT3 },
];

const HomeScreen = () => {
  return (
    <div style={{ background: '#fff' }}>
      <div
        style={{
          padding: '0 40px',
          borderBottom: '1px solid #000',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1
          style={{
            color: '#d51a76',
            fontSize: '64px',
          }}
        >
          Play Fun
        </h1>
        <h3 style={{ fontWeight: 600, fontSize: '48px' }}>
          Everything from NFT
        </h3>
      </div>
      <div style={{ padding: '45px' }}>
        <h6 style={{ textAlign: 'center', fontSize: '46px' }}>
          The Art Gallery
        </h6>
        <p
          style={{
            textAlign: 'center',
            marginTop: '10px',
            fontSize: '20px',
            fontWeight: 500,
          }}
        >
          Welcome to this magical world of art, masterpieces and amazing
          collections!
        </p>
      </div>

      <div
        style={{
          display: 'flex',
          padding: '0 0 40px 0',

          justifyContent: 'center',
          top: 0,
          left: 0,
          width: '100%',
        }}
      >
        <div className='move' style={{ gap: '150px', display: 'flex' }}>
          {ArtImages.map((item) => (
            <div key={item.id}>
              <img src={item.image} alt='' />
            </div>
          ))}
        </div>
      </div>

      {/* Middle Content */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          background: '#000',
          color: 'white',
        }}
      >
        <div
          style={{
            justifyContent: 'space-evenly',
            display: 'flex',
            padding: '150px 0',
            borderBottom: '1px solid #fff',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <h1>Collect digital art</h1>
            <h3> Buy and sell NFTs from the world’s top artists</h3>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <button
              style={{
                padding: '10px 50px',
                fontWeight: 500,
                fontSize: '24px',
                borderRadius: '45px',
              }}
            >
              Buy Now
            </button>
            <button
              style={{
                padding: '10px 50px',
                fontWeight: 500,
                fontSize: '24px',
                color: 'white',
                background: '#000',
                border: '2px solid #fff',
                borderRadius: '45px',
              }}
            >
              Learn More
            </button>
          </div>
        </div>
        <div
          style={{
            padding: '100px 0',
            display: 'flex',
            justifyContent: 'space-evenly',
            gap: '20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '10px',
              flexDirection: 'column',
            }}
          >
            <h6 style={{ fontSize: '64px' }}>The future of art collecting</h6>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '50px',
                alignItems: 'center',
              }}
            >
              <h4>
                Browse and build your collection of the world’s most
                cutting-edge digital art
              </h4>
              <button
                style={{
                  padding: '10px 50px',
                  fontWeight: 500,
                  fontSize: '24px',
                  color: 'white',
                  background: '#000',
                  border: '2px solid #fff',
                  borderRadius: '45px',
                  width: '50%',
                }}
              >
                Know More
              </button>
            </div>
          </div>
          <div style={{ borderLeft: '2px solid white' }} />
          <div
            style={{
              display: 'flex',
              gap: '10px',
              flexDirection: 'column',
            }}
          >
            <h6 style={{ fontSize: '64px' }}>Built for longevity</h6>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '50px',
                alignItems: 'center',
              }}
            >
              <h4>
                All transactions happen on-chain, creating a tamper-proof record
                of each artwork’s history
              </h4>

              <button
                style={{
                  padding: '10px 50px',
                  fontWeight: 500,
                  fontSize: '24px',
                  color: 'white',
                  background: '#000',
                  border: '2px solid #fff',
                  borderRadius: '45px',
                  width: '50%',
                }}
              >
                Know More
              </button>
            </div>
          </div>
        </div>
      </div>

      <div style={{ padding: '50px 200px' }}>
        <div
          id='carouselExampleIndicators'
          class='carousel slide'
          data-bs-ride='true'
        >
          <div class='carousel-indicators'>
            <button
              type='button'
              data-bs-target='#carouselExampleIndicators'
              data-bs-slide-to='0'
              class='active'
              aria-current='true'
              aria-label='Slide 1'
            ></button>
            <button
              type='button'
              data-bs-target='#carouselExampleIndicators'
              data-bs-slide-to='1'
              aria-label='Slide 2'
            ></button>
            <button
              type='button'
              data-bs-target='#carouselExampleIndicators'
              data-bs-slide-to='2'
              aria-label='Slide 3'
            ></button>
          </div>
          <div class='carousel-inner' style={{ borderRadius: '20px' }}>
            <div class='carousel-item active'>
              <img src={caraousel1} class='d-block w-100' alt='...' />
            </div>
            <div class='carousel-item'>
              <img src={caraousel2} class='d-block w-100' alt='...' />
            </div>
            <div class='carousel-item'>
              <img src={caraousel3} class='d-block w-100' alt='...' />
            </div>
          </div>
          <button
            class='carousel-control-prev'
            type='button'
            data-bs-target='#carouselExampleIndicators'
            data-bs-slide='prev'
          >
            <span class='carousel-control-prev-icon' aria-hidden='true'></span>
            <span class='visually-hidden'>Previous</span>
          </button>
          <button
            class='carousel-control-next'
            type='button'
            data-bs-target='#carouselExampleIndicators'
            data-bs-slide='next'
          >
            <span class='carousel-control-next-icon' aria-hidden='true'></span>
            <span class='visually-hidden'>Next</span>
          </button>
        </div>
      </div>

      <div
        style={{
          background: '#000',
          display: 'flex',
          justifyContent: 'space-evenly',
          color: 'white',
          padding: '50px 0',
        }}
      >
        <div>
          <div>
            <h2>Play Fun</h2>
            <h5>Follow on</h5>
            <div className='d-flex ' style={{ gap: '30px', margin: '30px 0' }}>
              <div className=''>
                <img className='img-fluid' src='assets/images/icon1.png' />
              </div>
              <div className=''>
                <img className='img-fluid' src='assets/images/icon2.png' />
              </div>
              <div className=''>
                <img className='img-fluid' src='assets/images/icon3.png' />
              </div>
              <div className=''>
                <img className='img-fluid' src='assets/images/icon4.png' />
              </div>
              <br />
              <br />
            </div>
            <span>
              <img
                className='img-fluid footer_play'
                src='assets/images/google-play-badge.png'
              />
            </span>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
          }}
        >
          <h5>Terms of services</h5>
          <h5>Privacy policy</h5>
          <h5>Safe Play</h5>
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
