import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Home_banner from '../Components/Home_banner';
import Build_clan from '../Components/Build_clan';
import Stay_informed from '../Components/Stay_informed';
import HomeScreen from '../Components/NewHomeScreen/HomeScreen';

function Home() {
  return (
    <>
      <Header active='home' />
      <main style={{ background: '#fff' }}>
        <HomeScreen />
        {/* <Home_banner />
        <Build_clan />
        <Stay_informed />
        <Footer /> */}
      </main>
    </>
  );
}

export default Home;
