import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home_banner from './Components/Home_banner';
import Build_clan from './Components/Build_clan';
import Stay_informed from './Components/Stay_informed';
import Home from "./pages/Home";
import Market_place from "./pages/Market_place";
import Prodcut from './pages/Product';
import Offermode from './pages/Offermode';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError
} from "@web3-react/core";

import { Web3Provider } from "@ethersproject/providers";
import Profile from './pages/Profile';
import CollectedNft from './pages/CollectedNft';
import SellCollectedNft from './pages/SellCollectedNft';
import OfferReceived from './pages/OfferReceived';
import Settings from './pages/Settings';

import Sellmarketplace from "./pages/Sellmarketplace";
import TermsandCondition from './pages/PrivacyPolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  function getLibrary(provider) {
    const library = new Web3Provider(provider);
    library.pollingInterval = 12000;
    return library;
  }
  return (
    <div className="App">
      <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route  path="Shop" element={<Market_place />}></Route>
          <Route path="product" element={<Prodcut />}></Route>
          <Route exact path="profile" element={<Profile/>}></Route>
          <Route  exact path="offermode" element={<Offermode/>}></Route>
          <Route  exact path="offerrecived" element={<OfferReceived/>}></Route>
          <Route path="collected-nft" element={<CollectedNft/>}></Route>
          <Route path="sell-collected-nft" element={<SellCollectedNft/>}></Route>
          <Route path="sell-market-nft" element={<Sellmarketplace/>}></Route>
          <Route exact  path="settings" element={<Settings/>}></Route>
          <Route exact  path="terms-condition" element={<TermsandCondition/>}></Route>
          <Route exact  path="privacy-policy" element={<PrivacyPolicy/>}></Route>
        </Routes>
      </BrowserRouter>
      </Web3ReactProvider>
    </div>
  );
}

export default App;
