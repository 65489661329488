import { useEffect, useState, useRef } from 'react';
import { ethers } from 'ethers';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import metamask_img from '../assets/images/metamask.png';
import tronLink_img from '../assets/images/tron-trx-logo.png';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import { render } from '@testing-library/react';
import newimg from '../assets/images/WalletConnect.png';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Web3 from 'web3';
import { Link, useNavigate } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { Web3Provider } from '@ethersproject/providers';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import user_default from '../assets/images/user_default.png';
import logoutimg from '../assets/images/logut.png';
import Profile from '../assets/images/profile.png';
import settings from '../assets/images/settings.png';
import favourite from '../assets/images/favourite.png';
import { useSelector, useDispatch } from 'react-redux';
import { isloged } from '../action/index';
import LoadingSpinner from '../pages/LoadingSpinner';
import ArtLogo from '../assets/images/ArtLogo.png';
function Header(props) {
  const isloged_ = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const isloged_change = () => {
    let value = {
      address: '0xAe1518f8f2D24B491778a085D64e24520758b5Cd',
    };
  };
  const [isLoading, setIsLoading] = useState(false);
  const captchaRef = useRef(null);
  const [show, setShow] = useState(false);
  const [walletconnected, setwalletconnect] = useState(false);
  const [dropdownstate, setdropdownstate] = useState(false);
  const [connectdropdownstate, setconnectdropdownstate] = useState(false);
  const [createshow, createsetShow] = useState(false);
  const [forgotshow, forgotsetShow] = useState(false);
  const [resetshow, resetsetShow] = useState(false);
  const [resetOtpshow, resetOtpsetShow] = useState(false);
  const [walletregister, Setwalletregister] = useState(false);
  const [showpassword, setshowpassword] = useState(false);
  const [createshowpassword, setcreateshowpassword] = useState(false);
  const [createRepeatshowpassword, setcreateRepeatshowpassword] =
    useState(false);
  const [Resetshowpassword, setResetshowpassword] = useState(false);
  const [ResetRepeatshowpassword, setResetRepeatshowpassword] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const createhandleShow = () => createsetShow(true);
  const createhandleClose = () => createsetShow(false);
  const forgothandleShow = () => forgotsetShow(true);
  const forgothandleClose = () => forgotsetShow(false);
  const resethandleShow = () => resetsetShow(true);
  const resethandleClose = () => resetsetShow(false);
  const resetOtphandleShow = () => resetOtpsetShow(true);
  const resetOtphandleClose = () => resetOtpsetShow(false);
  const wallet_register_handleShow = () => Setwalletregister(true);
  const wallet_register_handleClose = () => Setwalletregister(false);
  const register = () => {
    handleClose();
    createhandleShow();
    forgothandleClose();
  };
  const forgot = () => {
    // render_googlecapcha()
    handleClose();
    forgothandleShow();
  };
  const login = () => {
    forgothandleClose();
    handleShow();
  };
  const reset = () => {
    forgothandleClose();
    resethandleShow();
  };
  let isLogedin = localStorage.getItem('isLogedin');
  let token = localStorage.getItem('token');
  let wallet_connected = localStorage.getItem('wallet_connected');
  let walletype = localStorage.getItem('walletype');
  let loginConnection = localStorage.getItem('login-connection');
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  if (token) {
    const decodedJwt = parseJwt(token);
    if (decodedJwt.exp * 1000 < Date.now()) {
      // disconnect();
    }
  }
  // wallet_connected
  const active = props.active;
  // console.log(active);
  // const { walletype , setWalletType } = useState();
  const navigate = useNavigate();
  const success = (value) => {
    toast.success(value, { theme: 'colored' });
  };
  const faild = (value) => {
    toast.error(value, { theme: 'colored' });
  };
  const web3React = useWeb3React();
  const logout = () => {
    localStorage.removeItem('isLogedin');
    localStorage.removeItem('token');
    localStorage.removeItem('wallet_connected');
    setwalletconnect(false);
    navigate('/');
  };

  // console.log(web3React);

  const walletConnect = () => {
    // web3React.activate(new InjectedConnector());

    const walletconnect = new WalletConnectConnector({
      rpc: {
        1: 'https://frequent-quick-surf.bsc-testnet.discover.quiknode.pro/bba91df7e6fcc7f3fec561e6aca2b7bf095a8719/',
      },
      bridge: 'https://bridge.walletconnect.org',
      qrcode: true,
      pollingInterval: 12000,
    });

    web3React.activate(walletconnect);
  };
  const afterloginWalletConnect = () => {
    // web3React.activate(new InjectedConnector());

    const walletconnect = new WalletConnectConnector({
      rpc: {
        1: 'https://frequent-quick-surf.bsc-testnet.discover.quiknode.pro/bba91df7e6fcc7f3fec561e6aca2b7bf095a8719/',
      },
      bridge: 'https://bridge.walletconnect.org',
      qrcode: true,
      pollingInterval: 12000,
    });

    web3React.activate(walletconnect);
  };

  useEffect(() => {
    // console.log("new account ", web3React.account);
    if (web3React.account && !isLogedin) {
      axios
        .get(
          `${
            process.env.REACT_APP_Backend_url
          }/auth/meta-wallet-login?address=${web3React.account.toLowerCase()}`
        )
        .then(async (res) => {
          if (res.data.message === 'New User') {
            let data = res.data.data?.data;
            let nonce = res.data.data?.nonce;
            let address = web3React.account.toLowerCase();
            web3React.library
              .getSigner(web3React.account)
              .signMessage(data)
              .then((signature) => {
                localStorage.setItem('walletype', 'walletConnect');
                handleClose();
                wallet_register_handleShow();
              });
          } else if (res.data.message === 'User Exits') {
            let data = res.data.data?.data;
            let nonce = res.data.data?.nonce;
            let address = web3React.account.toLowerCase();
            web3React.library
              .getSigner(web3React.account)
              .signMessage(data)
              .then((signature) => {
                let login_data = {
                  signature,
                  address,
                  nonce,
                };
                axios
                  .post(
                    `${process.env.REACT_APP_Backend_url}/auth/meta-wallet-verify`,
                    login_data
                  )
                  .then(async (res) => {
                    if (res.data.message === 'Login done successfully') {
                      handleClose();
                      success(res.data.message);
                      localStorage.setItem('isLogedin', true);
                      localStorage.setItem('token', res.data?.data?.token);
                      localStorage.setItem(
                        'address',
                        res.data?.data?.userDetails.wallet_details.address
                      );
                      console.log(web3React);
                      localStorage.setItem('address', address);
                      localStorage.setItem('login-connection', true);
                      setTimeout(() => {
                        navigate('/Shop');
                      }, 2000);
                    }
                  });
              });
          }
        });
    } else if (web3React.account && isLogedin) {
      axios
        .get(
          `${
            process.env.REACT_APP_Backend_url
          }/auth/meta-wallet-afterLogin?address=${web3React.account.toLowerCase()}`,
          { headers: { 'x-access-token': `${token}` } }
        )
        .then(async (res) => {
          if (res.data.message === 'New User') {
            let data = res.data.data?.data;
            let nonce = res.data.data?.nonce;
            let address = web3React.account.toLowerCase();
            web3React.library
              .getSigner(web3React.account)
              .signMessage(data)
              .then((signature) => {
                axios
                  .get(
                    `${process.env.REACT_APP_Backend_url}/auth/meta-wallet-afterLoginForUpdate?address=${address}`,
                    { headers: { 'x-access-token': `${token}` } }
                  )
                  .then((res) => {
                    if (res.data.message === 'Conneted Success') {
                      localStorage.setItem('wallet_connected', true);
                      setwalletconnect(true);
                      setconnectdropdownstate(false);
                      success('Connected sucessfully');
                    }
                  });
                localStorage.setItem('walletype', 'walletConnect');
              });
          } else if (res.data.message === 'User Exits') {
            faild('Wallet address already exits');
          }
        });
    }
  }, [web3React.account]);
  const disconnect = async () => {
    try {
      setdropdownstate(false);
      setconnectdropdownstate(false);
      localStorage.removeItem('isLogedin');
      localStorage.removeItem('token');
      localStorage.removeItem('wallet_connected');
      setwalletconnect(false);
      localStorage.removeItem('isLogedin');
      localStorage.removeItem('login-connection');
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      web3React.deactivate();
      navigate('/');
    } catch (exception) {
      console.log(exception);
    }
  };
  const [data, setdata] = useState();
  const [address, setaddress] = useState('');
  useEffect(() => {
    // if(wallet_connected){
    //     if(walletype === "metaMask"){
    //         window.ethereum
    //         .request({ method: "eth_requestAccounts" })
    //         .then((res) => {
    //             setwalletconnect(true);
    //            // accountChangeHandler(res[0])
    //         });
    //     }else {

    //     }
    // }
    setdata({
      address: '',
      Balance: null,
    });
    if (loginConnection === 'true') {
      // console.log(wallet_connected);
      setwalletconnect(true);
    }
  }, []);
  const btnhandler = () => {
    // setShow(false);
    // Asking if metamask is already present or not
    if (window.ethereum) {
      window.ethereum.request({ method: 'eth_requestAccounts' }).then((res) => {
        accountChangeHandler(res[0]);
      });
    } else {
      alert('install metamask extension!!');
    }
  };
  const aftterBtnhandler = () => {
    // setShow(false);
    // Asking if metamask is already present or not
    if (window.ethereum) {
      window.ethereum.request({ method: 'eth_requestAccounts' }).then((res) => {
        afterloginConnet(res[0]);
        // accountChangeHandler(res[0])
      });
    } else {
      alert('install metamask extension!!');
    }
  };
  const getbalance = (address) => {
    // Requesting balance method
    window.ethereum
      .request({
        method: 'eth_getBalance',
        params: [address, 'latest'],
      })
      .then((balance) => {
        // Setting balance
        setdata({
          Balance: ethers.utils.formatEther(balance),
        });
      });
  };
  const accountChangeHandler = (account) => {
    // Setting an address data
    setaddress(account);
    login_address(account);
    // Setting a balance
    getbalance(account);
  };
  const Disconnect = () => {
    localStorage.removeItem('isLogedin');
    localStorage.removeItem('token');
    localStorage.removeItem('wallet_connected');
    setwalletconnect(false);
    localStorage.removeItem('wallet_connected');
    localStorage.removeItem('address');

    setdropdownstate(false);
    setaddress();
    navigate('/Shop');
  };
  const login_address = async (address) => {
    axios
      .get(
        `${process.env.REACT_APP_Backend_url}/auth/meta-wallet-login?address=${address}`
      )
      .then(async (res) => {
        if (res.data.message === 'New User') {
          const web3 = new Web3(window.ethereum);
          const userAccount = await web3.eth.getAccounts();
          console.log(web3.version);
          let data = res.data.data?.data;
          let nonce = res.data.data?.nonce;
          const hashedMessage = Web3.utils.fromUtf8(data);
          const signature = await window.ethereum.request({
            method: 'personal_sign',
            params: [hashedMessage, address],
          });
          localStorage.setItem('walletype', 'metaMask');
          handleClose();
          wallet_register_handleShow();
        } else if (res.data.message === 'User Exits') {
          const web3 = new Web3(window.ethereum);
          const userAccount = await web3.eth.getAccounts();
          console.log(web3.version);
          let data = res.data.data?.data;
          let nonce = res.data.data?.nonce;
          const hashedMessage = Web3.utils.fromUtf8(data);
          const signature = await window.ethereum.request({
            method: 'personal_sign',
            params: [hashedMessage, address],
          });
          if (signature) {
            let login_data = {
              signature,
              address,
              nonce,
            };
            axios
              .post(
                `${process.env.REACT_APP_Backend_url}/auth/meta-wallet-verify`,
                login_data
              )
              .then(async (res) => {
                if (res.data.message === 'Login done successfully') {
                  handleClose();
                  console.log(res);
                  success(res.data.message);
                  localStorage.setItem('isLogedin', true);
                  localStorage.setItem('token', res.data?.data?.token);
                  localStorage.setItem('address', address);
                  localStorage.setItem('login-connection', true);
                  setTimeout(() => {
                    navigate('/Shop');
                  }, 2000);
                } else {
                  faild(res.data.message);
                }
              });
          }
        } else {
          faild(res.data.message);
        }
      });
  };
  const afterloginConnet = async (address) => {
    if (wallet_connected !== 'true') {
      axios
        .get(
          `${process.env.REACT_APP_Backend_url}/auth/meta-wallet-afterLogin?address=${address}`,
          { headers: { 'x-access-token': `${token}` } }
        )
        .then(async (res) => {
          if (res.data.message === 'New User') {
            const web3 = new Web3(window.ethereum);
            const userAccount = await web3.eth.getAccounts();
            console.log(web3.version);
            let data = res.data.data?.data;
            let nonce = res.data.data?.nonce;
            const hashedMessage = Web3.utils.fromUtf8(data);
            const signature = await window.ethereum
              .request({
                method: 'personal_sign',
                params: [hashedMessage, address],
              })
              .then((res) => {
                axios
                  .get(
                    `${process.env.REACT_APP_Backend_url}/auth/meta-wallet-afterLoginForUpdate?address=${address}`,
                    { headers: { 'x-access-token': `${token}` } }
                  )
                  .then((res) => {
                    if (res.data.message === 'Conneted Success') {
                      localStorage.setItem('wallet_connected', true);
                      setwalletconnect(true);
                      setconnectdropdownstate(false);
                      success('Connected sucessfully');
                      localStorage.setItem('login-connection', true);
                      localStorage.setItem('address', address);
                    }
                  });
              });
            localStorage.setItem('walletype', 'metaMask');
          } else if (res.data.message === 'User Exits') {
            faild('Wallet address already exits');
          } else {
            faild(res.data.message);
          }
        });
    } else {
      window.ethereum.request({ method: 'eth_requestAccounts' }).then((res) => {
        console.log(res);
        wallet_address_check(res[0]);
        // setwalletconnect(true);
        // accountChangeHandler(res[0])
      });
    }
  };
  const wallet_address_check = (address) => {
    axios
      .get(
        `${process.env.REACT_APP_Backend_url}/auth/wallet-login-check?address=${address}`,
        { headers: { 'x-access-token': `${token}` } }
      )
      .then(async (res) => {
        console.log(res);
        if (res.data.message === 'Invalid') {
          faild('Invalid wallet Address');
        } else if (res.data.message === 'success') {
          success('Connect successfully');
          setwalletconnect(true);
          setconnectdropdownstate(false);
          localStorage.setItem('login-connection', true);
          window.location.reload();
        }
      });
  };
  const afterloginWalletconnet = async (address) => {
    axios
      .get(
        `${process.env.REACT_APP_Backend_url}/auth/meta-wallet-afterLogin?address=${address}`,
        { headers: { 'x-access-token': `${token}` } }
      )
      .then(async (res) => {
        if (res.data.message === 'New User') {
          const web3 = new Web3(window.ethereum);
          const userAccount = await web3.eth.getAccounts();
          console.log(web3.version);
          let data = res.data.data?.data;
          let nonce = res.data.data?.nonce;
          const hashedMessage = Web3.utils.fromUtf8(data);
          const signature = await window.ethereum
            .request({
              method: 'personal_sign',
              params: [hashedMessage, address],
            })
            .then((res) => {
              axios
                .get(
                  `${process.env.REACT_APP_Backend_url}/auth/meta-wallet-afterLoginForUpdate?address=${address}`,
                  { headers: { 'x-access-token': `${token}` } }
                )
                .then((res) => {
                  if (res.data.message === 'Conneted Success') {
                    localStorage.setItem('wallet_connected', true);
                    setwalletconnect(true);
                    setconnectdropdownstate(false);
                    success('Connected sucessfully');
                  }
                });
            });
          localStorage.setItem('walletype', 'metaMask');
          // handleClose();
          // wallet_register_handleShow();
        } else if (res.data.message === 'User Exits') {
          faild('Wallet address already exits');
        } else {
          faild(res.data.message);
        }
      });
  };
  const Connect = () => {
    // setShow(false)
    if (window.tronLink) {
      var acccount = window.tronLink
        .request({ method: 'tron_requestAccounts' })
        .then((res) => {
          if (res.message === 'The site is already in the whitelist') {
            if (window.tronWeb?.ready) {
              setaddress(window.tronWeb.defaultAddress.base58);
              login_address(window.tronWeb.defaultAddress.base58);
            }
          } else if (res.message === 'User rejected the request.') {
            faild('Connection rejected');
          } else if (res.message === 'User allowed the request.') {
            setaddress(window.tronWeb.defaultAddress.base58);
            login_address(window.tronWeb.defaultAddress.base58);
          } else if (res.message === undefined) {
            faild('please unlock your wallet again connect your wallet');
          }
        });
    } else {
      faild('please install tron wallet again connect your wallet');
    }
  };
  const TEST_SITE_KEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmpassword: '',
      terms: false,
      notify: false,
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Email must be valid email')
        .required('Email is required'),
      password: yup
        .string()
        .required('Password is required')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
        ),
      confirmpassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
      terms: yup
        .boolean()
        .oneOf(
          [true],
          'You must accept the pricing policy terms and conditions'
        ),
      notify: yup
        .boolean()
        .oneOf(
          [true],
          'You must accept the pricing policy terms and conditions'
        ),
    }),
    onSubmit: (userinputdata) => {
      let registerdate = {
        email: userinputdata.email,
        password: userinputdata.password,
        confirm_password: userinputdata.confirmpassword,
      };

      axios
        .post(
          `${process.env.REACT_APP_Backend_url}/auth/register`,
          registerdate
        )
        .then((res) => {
          if (res.data.message === 'Registration done successfully') {
            success(res.data.message);
            createhandleClose();
          } else {
            faild(res.data.message);
          }
        });
    },
  });
  const formik_login = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Email must be valid email')
        .required('Email is required'),
      password: yup
        .string()
        .required('Password is required')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
        ),
    }),
    onSubmit: (userinputdata) => {
      setIsLoading(true);
      let login_data = {
        email: userinputdata.email,
        password: userinputdata.password,
      };
      handleClose();
      isloged_change();
      axios
        .post(`${process.env.REACT_APP_Backend_url}/auth/login`, login_data)
        .then((res) => {
          if (res.data.message === 'Login done successfully') {
            success(res.data.message);
            localStorage.setItem('isLogedin', true);
            localStorage.setItem('token', res.data?.data?.token);
            // localStorage.setItem('wallet_connected', res.data?.data?.userDetails.wallet_details);
            localStorage.setItem(
              'wallet_connected',
              res.data?.data?.userDetails.wallet_details.connected
            );
            localStorage.setItem(
              'address',
              res.data?.data?.userDetails.wallet_details.address
            );
            console.log(res.data.data);

            setIsLoading(false);
            setTimeout(() => {
              navigate('/Shop');
            }, 2000);
          } else {
            faild(res.data.message);
          }
        });
    },
  });
  const formik_forgot = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Email must be valid email')
        .required('Email is required'),
    }),
    onSubmit: (userinputdata) => {
      let login_data = {
        email: userinputdata.email,
      };

      axios
        .post(
          `${process.env.REACT_APP_Backend_url}/auth/forgot_password`,
          login_data
        )
        .then((res) => {
          if (res.data.message === 'Otp sent successfully') {
            forgothandleClose();
            localStorage.setItem('forgotmail', res.data.data?.mail);
            success(res.data.message);
            resetOtphandleShow();
          } else {
            faild(res.data.message);
          }
          console.log(res);
        });
    },
  });
  const formik_reset = useFormik({
    initialValues: {
      resetpassword: '',
      resetconfirmpassword: '',
    },
    validationSchema: yup.object({
      resetpassword: yup
        .string()
        .required('Password is required')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
        ),
      resetconfirmpassword: yup
        .string()
        .oneOf([yup.ref('resetpassword'), null], 'Passwords must match'),
    }),
    onSubmit: (userinputdata) => {
      let login_data = {
        email: localStorage.getItem('forgotmail'),
        password: userinputdata.resetpassword,
        confirm_password: userinputdata.resetconfirmpassword,
      };
      axios
        .post(
          `${process.env.REACT_APP_Backend_url}/auth/change_password_forgot`,
          login_data
        )
        .then((res) => {
          if (res.data.message === 'Changed password successfully') {
            resethandleClose();
            success(res.data.message);
          } else {
            faild(res.data.message);
          }
        });
    },
  });
  const formik_reset_Otp = useFormik({
    initialValues: {
      otp: '',
    },
    validationSchema: yup.object({
      otp: yup.number('Otp must be number').required('Otp is required'),
    }),
    onSubmit: (userinputdata) => {
      let login_data = {
        otp: userinputdata.otp,
        email: localStorage.getItem('forgotmail'),
      };

      axios
        .post(
          `${process.env.REACT_APP_Backend_url}/auth/verify_reset_otp`,
          login_data
        )
        .then((res) => {
          if (res.data.message === 'Otp verified successfully') {
            resetOtphandleClose();
            success(res.data.message);
            resethandleShow();
          } else {
            faild(res.data.message);
          }
        });
    },
  });
  const formik_wallet_register = useFormik({
    initialValues: {
      email: '',
      confirm_email: '',
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Email must be valid email')
        .required('Email is required'),
      confirm_email: yup
        .string()
        .oneOf([yup.ref('email'), null], 'Please enter same email'),
    }),
    onSubmit: async (userinputdata) => {
      const web3 = new Web3(window.ethereum);
      let walletype = await localStorage.getItem('walletype');
      // console.log(walletype);
      let userAccount_;
      if (walletype === 'walletConnect') {
        userAccount_ = web3React.account;
        console.log(web3React.account);
      } else {
        userAccount_ = await web3.eth.getAccounts();
        userAccount_ = userAccount_[0];
      }

      let login_data = {
        email: userinputdata.email,
        confirm_email: userinputdata.confirm_email,
        userAccount: userAccount_,
      };

      axios
        .post(
          `${process.env.REACT_APP_Backend_url}/auth/wallet-register`,
          login_data
        )
        .then((res) => {
          if (res.data.message === 'Registration done successfully') {
            if (walletype === 'walletConnect') {
              web3React.deactivate();
            }
            wallet_register_handleClose();
            success(res.data.message);
          } else {
            faild(res.data.message);
          }
        });
    },
  });
  useEffect(() => {});
  const setconnectdropdownstate_ = () => {
    setconnectdropdownstate(!connectdropdownstate);
    setdropdownstate(false);
  };
  const setdropdownstate_ = () => {
    setconnectdropdownstate(false);
    setdropdownstate(!dropdownstate);
  };
  const termsCondition = () => {
    navigate('/terms-condition');
  };
  const privacypolicy = () => {
    navigate('/privacy-policy');
  };
  const password = () => {
    setshowpassword(!showpassword);
  };
  const Createpassword = () => {
    setcreateshowpassword(!createshowpassword);
  };
  const CreateRepeatpassword = () => {
    setcreateRepeatshowpassword(!createRepeatshowpassword);
  };
  const Resetpassword = () => {
    setResetshowpassword(!Resetshowpassword);
  };
  const RepeatResetpassword = () => {
    setResetRepeatshowpassword(!ResetRepeatshowpassword);
  };
  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
      <header>
        <nav
          className='navbar navbar-expand-sm '
          style={{
            background: '#000',
            color: 'white',

            padding: 0,
          }}
        >
          <div className='container'>
            <Link style={{ color: 'white' }} className='navbar-brand' to='/'>
              <img src={ArtLogo} className='img-fluid logo' />
            </Link>
            <button
              className='navbar-toggler'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#mynavbar'
            >
              <span className='navbar-toggler-icon'></span>
            </button>
            <div className='collapse navbar-collapse' id='mynavbar'>
              <ul className='navbar-nav  mx-auto' style={{ gap: '40px' }}>
                <li className='nav-item'>
                  <Link
                    style={{ color: 'white' }}
                    to='/'
                    className={
                      active === 'home' ? 'nav-link active' : 'nav-link'
                    }
                  >
                    Home
                  </Link>
                </li>
                <li className='nav-item'>
                  <a
                    style={{ color: 'white' }}
                    className={
                      active === 'team' ? 'nav-link active' : 'nav-link'
                    }
                    href='#'
                  >
                    Gallery
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    style={{ color: 'white' }}
                    className={
                      active === 'schedule' ? 'nav-link active' : 'nav-link'
                    }
                    href='#'
                  >
                    Top Selling
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    style={{ color: 'white' }}
                    className={
                      active === 'whitepaper' ? 'nav-link active' : 'nav-link'
                    }
                    href='#'
                  >
                    Most Rated
                  </a>
                </li>

                <li className='nav-item'>
                  <Link
                    style={{ color: 'white' }}
                    className={
                      active === 'shop' || active === 'product'
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                    to='/Shop'
                  >
                    Market place
                  </Link>
                </li>
              </ul>
              <div className='d-flex m_resp align-items-center after-login'>
                {!isLogedin ? (
                  <>
                    <button
                      className='btn btn-primary signin me-2'
                      onClick={handleShow}
                      type='button'
                      style={{
                        background: '#e1377b',
                        color: '#fff',
                        border: '1px solid #e1377b',
                      }}
                    >
                      Signin
                    </button>
                  </>
                ) : null}

                {web3React.account && !walletconnected ? (
                  <>
                    {/* <button className="btn btn-primary connected " type="button">Connected {address}</button> */}

                    <button
                      className='btn btn-primary connect'
                      onClick={() => disconnect()}
                      type='button'
                    >
                      Disconnect
                    </button>
                  </>
                ) : (
                  <>
                    {isLogedin ? (
                      <>
                        <img
                          onClick={() => setdropdownstate_()}
                          src={user_default}
                          width={25}
                          height={25}
                          className='profile'
                        />

                        {/* <button className="btn btn-primary connect" onClick={() => disconnect()} type="button">Disconnect</button> */}
                      </>
                    ) : null}
                  </>
                )}
                {isLogedin ? (
                  <>
                    {/* {console.log(walletconnect)} */}
                    {walletconnected ? (
                      <button className='btn btn-primary connect' type='button'>
                        Connected
                      </button>
                    ) : (
                      <button
                        className='btn btn-primary connect-wallet'
                        onClick={() => setconnectdropdownstate_()}
                        type='button'
                      >
                        Connect Wallet
                      </button>
                    )}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </nav>
      </header>
      {dropdownstate ? (
        <>
          <div className='userpopup'>
            <li>
              <Link style={{ color: 'white' }} to='/profile'>
                <img width={20} height={20} heigh src={Profile} />
                Profile
              </Link>
            </li>
            {/* <li><img width={20} height={20} src={favourite} />Favourites</li> */}
            <li>
              <Link style={{ color: 'white' }} to='/settings'>
                <img width={20} height={20} src={settings} />
                Settings
              </Link>
            </li>
            <li onClick={() => disconnect()}>
              <img width={20} height={20} src={logoutimg} />
              Logout
            </li>
          </div>
        </>
      ) : null}
      {connectdropdownstate ? (
        <>
          <div className='walletconnectpopup'>
            <li> Please connect to a wallet</li>
            <div className='submitForm'>
              <input name='submit' onClick={() => aftterBtnhandler()} />
            </div>

            <div className='submit-connect'>
              <input
                name='submit-connect2'
                onClick={() => afterloginWalletConnect()}
              />
            </div>
          </div>
        </>
      ) : null}
      {/* Login  */}
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName='modal-95w'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Modal.Body>
          <div className='container-fluid'>
            <div className='row viking-login'>
              <div className='text-right'>
                <button
                  className='modal-close-button'
                  onClick={handleClose}
                ></button>
              </div>
              <div className='col-lg-6 horizontal-line'>
                {/* <div className="horizontal-line"> */}
                <form onSubmit={formik_login.handleSubmit}>
                  <h2 className='login-title'>Login</h2>
                  <h3 className='login-subtitle'>
                    Enter Credentials to continue
                  </h3>
                  <div className='input-container'>
                    <i className='fa fa-user icon'></i>
                    <input
                      className='input-field'
                      onChange={formik_login.handleChange}
                      type='text'
                      placeholder='Email Address'
                      name='email'
                    />
                  </div>
                  <div className='error'>
                    {formik_login.errors.email ? (
                      <div className='text-danger'>
                        {formik_login.errors.email}{' '}
                      </div>
                    ) : null}
                  </div>
                  <div className='input-container'>
                    <i className='fa fa-key icon'></i>
                    <input
                      className='input-field'
                      onChange={formik_login.handleChange}
                      type={showpassword ? 'text' : 'password'}
                      placeholder='Password'
                      name='password'
                    />
                    <i
                      class={showpassword ? 'bi bi-eye' : 'bi bi-eye-slash'}
                      onClick={() => password()}
                      id='togglePassword'
                    ></i>
                  </div>
                  <div className='error'>
                    {formik_login.errors.password ? (
                      <div className='text-danger'>
                        {formik_login.errors.password}{' '}
                      </div>
                    ) : null}
                  </div>
                  <button type='submit' className='login-button'>
                    Login
                  </button>

                  <div className='float-right'>
                    <a className='forgot-button' onClick={() => forgot()}>
                      Forgot Password?
                    </a>
                  </div>
                  <h2 className='text-span'>
                    <span className='span'>OR</span>
                  </h2>
                </form>

                <div className='submitForm'>
                  <input name='submit' onClick={() => btnhandler()} />
                </div>

                <div className='submit-connect'>
                  <input
                    name='submit-connect2'
                    onClick={() => walletConnect()}
                  />
                </div>
                <div className='submit-conne'> </div>
                {/* <img src={newimg} ></img> */}
                <br />
                <br />
                {/* </div> */}
              </div>
              <div className='col-lg-6 login-right '>
                <center>
                  <h2 className='login-title text-center '>New To</h2>
                  <h2 className='login-title text-center mt5'>Play Fun? </h2>
                  <h3 className='login-subtitle'>
                    Get Started with a Free Accouunt
                  </h3>
                  <button
                    className='create-button mt10 '
                    onClick={() => {
                      register();
                    }}
                  >
                    Create Account
                  </button>
                </center>
              </div>
              {/* </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Forgot password  */}
      <Modal
        show={forgotshow}
        onHide={forgothandleClose}
        dialogClassName='modal-90w'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Modal.Body>
          <div className='container-fluid'>
            <div className='row viking-login'>
              <div className='text-right'>
                <button
                  className='modal-close-button'
                  onClick={forgothandleClose}
                ></button>
              </div>
              <div className='col-lg-6 horizontal-line'>
                {/* <div className="horizontal-line"> */}
                <form onSubmit={formik_forgot.handleSubmit}>
                  <h2 className='login-title'>Forgot Password</h2>
                  <h3 className='login-subtitle'>
                    Please enter the email-id associated with your account. For
                    more assistance please contact our Support.
                  </h3>
                  <div className='input-container'>
                    <i className='fa fa-user icon'></i>
                    <input
                      className='input-field'
                      onChange={formik_forgot.handleChange}
                      type='text'
                      placeholder='Email Address'
                      name='email'
                    />
                  </div>
                  <div className='error'>
                    {formik_forgot.errors.email ? (
                      <div className='text-danger'>
                        {formik_forgot.errors.email}{' '}
                      </div>
                    ) : null}
                  </div>
                  <div className='width80'>
                    <center>
                      {/* {forgotshow ? (
                                                <> */}
                      <ReCAPTCHA
                        style={{ display: 'inline-block' }}
                        theme='light'
                        ref={captchaRef}
                        sitekey={TEST_SITE_KEY}
                        render='explicit'
                        // onChange={this.handleChange}
                        // asyncScriptOnLoad={this.asyncScriptOnLoad}
                      />
                      {/* </>
                                            ) : null} */}
                    </center>
                  </div>
                  <button type='submit' className='forgot-button'>
                    Send Link
                  </button>

                  <div className='float-right'>
                    <a className='forgot-button' onClick={() => login()}>
                      Login
                    </a>
                  </div>
                  <h2 className='text-span'>
                    <span className='span'>OR</span>
                  </h2>
                </form>

                <div className='submitForm'>
                  <input name='submit' />
                </div>

                <div className='submit-connect'>
                  <input name='submit-connect2' />
                </div>
                <br />
                <br />
                {/* </div> */}
              </div>
              <div className='col-lg-6 login-right '>
                <center>
                  <h2 className='login-title text-center '>New To</h2>
                  <h2 className='login-title text-center mt5'>Play Fun?</h2>
                  <h3 className='login-subtitle'>
                    Get Started with a Free Accouunt
                  </h3>
                  <button
                    className='create-button mt10 '
                    onClick={() => {
                      register();
                    }}
                  >
                    Create Account
                  </button>
                </center>
              </div>
              {/* </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Register new account */}
      <Modal
        show={createshow}
        onHide={createhandleClose}
        dialogClassName='modal-50w'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Modal.Body>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12 '>
                <div className='text-right'>
                  <button
                    className='modal-close-button'
                    onClick={createhandleClose}
                  ></button>
                </div>
                {/* <div className="horizontal-line"> */}
                <form className='register-form' onSubmit={formik.handleSubmit}>
                  <h2 className='register-title'>Create Account </h2>
                  <h3 className='register-subtitle'>
                    Enter email address and password
                  </h3>
                  <center>
                    <div className='input-container'>
                      <i className='fa fa-user icon'></i>
                      <input
                        className='input-field'
                        onChange={formik.handleChange}
                        type='text'
                        placeholder='Email Address'
                        name='email'
                      />
                    </div>
                    <div className='error'>
                      {formik.errors.email ? (
                        <div className='text-danger'>
                          {formik.errors.email}{' '}
                        </div>
                      ) : null}
                    </div>

                    <div className='input-container'>
                      <i className='fa fa-key icon'></i>
                      <input
                        className='input-field'
                        onChange={formik.handleChange}
                        type={createshowpassword ? 'test' : 'password'}
                        placeholder='Password'
                        name='password'
                      />
                      <i
                        class={
                          createshowpassword ? 'bi bi-eye' : 'bi bi-eye-slash'
                        }
                        onClick={() => Createpassword()}
                        id='togglePassword'
                      ></i>
                    </div>
                    <div className='error'>
                      {formik.errors.password ? (
                        <div className='text-danger'>
                          {formik.errors.password}{' '}
                        </div>
                      ) : null}
                    </div>
                    <div className='input-container'>
                      <i className='fa fa-key icon'></i>
                      <input
                        className='input-field'
                        onChange={formik.handleChange}
                        type={createRepeatshowpassword ? 'text' : 'password'}
                        placeholder='Repeat Password'
                        name='confirmpassword'
                      />
                      <i
                        class={
                          createRepeatshowpassword
                            ? 'bi bi-eye'
                            : 'bi bi-eye-slash'
                        }
                        onClick={() => CreateRepeatpassword()}
                        id='togglePassword'
                      ></i>
                    </div>
                    <div className='error'>
                      {formik.errors.confirmpassword ? (
                        <div className='text-danger'>
                          {formik.errors.confirmpassword}{' '}
                        </div>
                      ) : null}
                    </div>
                    <div className='form-check mt-4'>
                      <input
                        className='form-check-input'
                        onClick={formik.handleChange}
                        type='checkbox'
                        name='terms'
                        id='flexCheckDefault'
                      />
                      <label
                        className='form-check-label'
                        for='flexCheckDefault'
                      >
                        Notify me about offers and Updates(Optional)
                      </label>
                    </div>
                    <div className='error'>
                      {formik.errors.terms ? (
                        <div className='text-danger'>
                          {formik.errors.terms}{' '}
                        </div>
                      ) : null}
                    </div>
                    <br />
                    <div className='form-check '>
                      <input
                        className='form-check-input'
                        onChange={formik.handleChange}
                        type='checkbox'
                        name='notify'
                        id='flexCheckDefault1'
                      />
                      <label
                        className='form-check-label'
                        for='flexCheckDefault1'
                      >
                        {' '}
                        By checking this box, you accept the{' '}
                        <span onClick={() => privacypolicy()}>
                          {' '}
                          privacy policy
                        </span>{' '}
                        &nbsp;and&nbsp;{' '}
                        <span onClick={() => termsCondition()}>
                          {' '}
                          Terms of Service.
                        </span>
                      </label>
                    </div>

                    <br />
                    <div className='error'>
                      {formik.errors.notify ? (
                        <div className='text-danger'>
                          {formik.errors.notify}{' '}
                        </div>
                      ) : null}
                    </div>
                    <br />
                    <button type='submit' className='register-button mb-4'>
                      Continue
                    </button>
                  </center>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Reset password */}
      <Modal
        show={resetshow}
        onHide={resethandleClose}
        dialogClassName='modal-50w'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Modal.Body>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12 '>
                <div className='text-right'>
                  <button
                    className='modal-close-button'
                    onClick={resethandleClose}
                  ></button>
                </div>
                {/* <div className="horizontal-line"> */}
                <form
                  className='register-form'
                  onSubmit={formik_reset.handleSubmit}
                >
                  <h2 className='register-title'>Reset Password</h2>
                  <h3 className='register-subtitle'>Enter new password</h3>
                  <center>
                    <div className='input-container'>
                      <i className='fa fa-key icon'></i>
                      <input
                        onChange={formik_reset.handleChange}
                        className='input-field'
                        type={Resetshowpassword ? 'text' : 'password'}
                        placeholder='Password'
                        name='resetpassword'
                      />
                      <i
                        class={
                          createRepeatshowpassword
                            ? 'bi bi-eye'
                            : 'bi bi-eye-slash'
                        }
                        onClick={() => Resetpassword()}
                        id='togglePassword'
                      ></i>
                    </div>
                    <div className='error'>
                      {formik_reset.errors.resetpassword ? (
                        <div className='text-danger'>
                          {formik_reset.errors.resetpassword}{' '}
                        </div>
                      ) : null}
                    </div>
                    <div className='input-container'>
                      <i className='fa fa-key icon'></i>
                      <input
                        onChange={formik_reset.handleChange}
                        className='input-field'
                        type={ResetRepeatshowpassword ? 'text' : 'password'}
                        placeholder='Repeat Password'
                        name='resetconfirmpassword'
                      />
                      <i
                        class={
                          ResetRepeatshowpassword
                            ? 'bi bi-eye'
                            : 'bi bi-eye-slash'
                        }
                        onClick={() => RepeatResetpassword()}
                        id='togglePassword'
                      ></i>
                    </div>
                    <div className='error'>
                      {formik_reset.errors.resetconfirmpassword ? (
                        <div className='text-danger'>
                          {formik_reset.errors.resetconfirmpassword}{' '}
                        </div>
                      ) : null}
                    </div>

                    <br />
                    <br />
                    <button type='submit' className='register-button'>
                      Reset
                    </button>
                  </center>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={resetOtpshow}
        onHide={resetOtphandleClose}
        dialogClassName='modal-50w'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Modal.Body>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12 '>
                <div className='text-right'>
                  <button
                    className='modal-close-button'
                    onClick={resetOtphandleClose}
                  ></button>
                </div>
                {/* <div className="horizontal-line"> */}
                <form
                  className='register-form'
                  onSubmit={formik_reset_Otp.handleSubmit}
                >
                  <h2 className='register-title'>Otp verify</h2>
                  <h3 className='register-subtitle'>Enter Otp</h3>
                  <center>
                    <div className='input-container'>
                      <i className='fa fa-key icon'></i>
                      <input
                        onChange={formik_reset_Otp.handleChange}
                        className='input-field'
                        type='text'
                        placeholder='Enter Otp'
                        name='otp'
                      />
                    </div>
                    <div className='error'>
                      {formik_reset_Otp.errors.otp ? (
                        <div className='text-danger'>
                          {formik_reset_Otp.errors.otp}{' '}
                        </div>
                      ) : null}
                    </div>
                    <br />
                    <br />
                    <button type='submit' className='register-button'>
                      Reset
                    </button>
                  </center>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Wallet Register new account */}
      <Modal
        show={walletregister}
        onHide={wallet_register_handleClose}
        dialogClassName='modal-50w'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Modal.Body>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12 '>
                <div className='text-right'>
                  <button
                    className='modal-close-button'
                    onClick={wallet_register_handleClose}
                  ></button>
                </div>
                {/* <div className="horizontal-line"> */}
                <form
                  className='register-form'
                  onSubmit={formik_wallet_register.handleSubmit}
                >
                  <h2 className='register-title'>Create Account</h2>
                  <h3 className='register-subtitle'>Enter Email address</h3>
                  <center>
                    <div className='input-container'>
                      <i className='fa fa-key icon'></i>
                      <input
                        className='input-field'
                        onChange={formik_wallet_register.handleChange}
                        type='email'
                        placeholder='Enter Email Address'
                        name='email'
                      />
                    </div>
                    <div className='error'>
                      {formik_wallet_register.errors.email ? (
                        <div className='text-danger'>
                          {formik_wallet_register.errors.email}{' '}
                        </div>
                      ) : null}
                    </div>
                    <div className='input-container'>
                      <i className='fa fa-key icon'></i>
                      <input
                        className='input-field'
                        onChange={formik_wallet_register.handleChange}
                        type='email'
                        placeholder='Confirm Email Address'
                        name='confirm_email'
                      />
                    </div>
                    <div className='error'>
                      {formik_wallet_register.errors.confirm_email ? (
                        <div className='text-danger'>
                          {formik_wallet_register.errors.confirm_email}{' '}
                        </div>
                      ) : null}
                    </div>
                    <br />
                    <br />
                    <button type='submit' className='register-button'>
                      Submit
                    </button>
                  </center>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Header;
