import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Home_banner from '../Components/Home_banner';
import Build_clan from '../Components/Build_clan';
import Stay_informed from '../Components/Stay_informed';
import '../Styles/market_place.css';
import Web3 from 'web3';
import market_place from '../assets/images/market_place.png';
import cart_card from './check.gif';
import eth from '../assets/images/eth.png';
import soldOut from '../assets/images/sold-out.png';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Sellmarketplace from './Sellmarketplace';
let web3 = new Web3(window.ethereum);

function Market_place() {
  const [showbuy, setshowbuy] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  // console.log(showbuy);
  const [nftcard, setnftcard] = useState();
  const [_acc, setAcc] = React.useState();
  const [walletAddr, setwallet] = React.useState();
  let isLogedin = localStorage.getItem('isLogedin');
  const [imageuri, setimage] = React.useState(cart_card);
  let token = localStorage.getItem('token');
  const navigate = useNavigate();
  const details_page = (id) => {
    navigate(`/product?id=${id}`);
  };
  const detailsAfterSell = (id) => {
    navigate(`/sell-market-nft?id=${id}`);
  };
  const detailsAfterSellBid = (id) => {
    navigate(`/sell-market-nft?id=${id}`);
  };
  useEffect(() => {
    async function dataaa() {
      // await loadWeb3();
      // let accounts = await window.web3.eth.getAccounts();
      axios
        .get(`${process.env.REACT_APP_Backend_url}/auth/get-all-mintednft`, {
          headers: { 'x-access-token': `${token}` },
        })
        .then(async (res) => {
          // console.log(res.data?.data._userDetail[0]?.Nft_data);
          setnftcard(res.data?.data?._userDetail);
          setimage(res.data?.data?._userDetail[0]?.image);
          let Nft_data = res.data?.data?._userDetail;
          console.log('nft-', res.data?.data?._userDetail[0].owner);
          Nft_data.map((card, index) => {
            let token_id = card.token_id;
            // let owner = card.owner;
            // console.log("o-",owner);
            var index = showbuy.indexOf(token_id);
            if (index >= 0) {
              let buyable = showbuy.splice(index, 1);
            }
          });
          // setAcc(accounts[0]);
          // console.log(accounts[0]);
        });

      await loadWeb3();
      let accounts = await window.web3.eth.getAccounts();
      axios
        .get(`${process.env.REACT_APP_Backend_url}/auth/get-all-buynft`, {
          headers: { 'x-access-token': `${token}` },
        })
        .then(async (res) => {
          console.log(
            'res-',
            res.data?.data?._userDetail[0].wallet_details.address
          );
          // console.log("res-",res.data?.data?._userDetail[0]);
          if (res.data?.data?._userDetail[0]?.wallet_details) {
            setwallet(res.data?.data._userDetail[0]?.wallet_details.address);
            // setminted(true);
          } else if (res.data?.data?._userDetail[0]) {
          }
        });
      setAcc(accounts[0]);
    }
    dataaa();
  }, []);

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        'Non-Ethereum browser detected. You should consider trying MetaMask!'
      );
    }
  };

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      cursor: 'pointer',
    }),
    control: (styles) => ({
      ...styles,
      cursor: 'pointer',
    }),
  };
  return (
    <>
      <Header active='shop' />
      <main style={{ background: '#160038' }}>
        <div className='container market-place-section'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='row'>
                <div className='col-md-4 offset-md-4 '>
                  <center>
                    <div className='d-flex justify-content-center'>
                      <img className='market-img' src={market_place} />

                      <h2 className='market-place-title'>Marketplace</h2>
                    </div>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container '>
          <div className='row'>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='input-group'>
                <label className='input-group-text filter' htmlFor=''>
                  <i className='bi bi-funnel'></i>
                </label>
                <select className='filter-select'>
                  <option style={customStyles}>Filter</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                </select>
              </div>
              <div className='input-group justify-content-end'>
                <label className='input-group-text sort' htmlFor=''>
                  <i className='fa fa-sort'></i>
                </label>
                <select className='sort-select'>
                  <option>Sort</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              {nftcard?.length > 0 &&
                nftcard?.map((card, index) => {
                  return (
                    <div
                      key={index}
                      className='card'
                      onClick={() => {
                        if (card.status === 'Sold') {
                          detailsAfterSell(card.token_id);
                        } else if (
                          card.status === 'Sell' ||
                          card.status === 'Sell-Auction' ||
                          card.status === 'Auction'
                        ) {
                          detailsAfterSell(card.token_id);
                        } else {
                          details_page(card.token_id);
                        }
                      }}
                    >
                      <div className='card-section'>
                        {console.log('owner', card.owner)}
                        {console.log(
                          'wallet',
                          web3.utils.toChecksumAddress(walletAddr)
                        )}
                        {console.log(card.status)}
                        {card.status === 'Sold' &&
                        card.owner ===
                          web3.utils.toChecksumAddress(walletAddr) ? (
                          <img src={card.image} alt='Denim Jeans' />
                        ) : (
                          <>
                            {card.status === 'Sell-Auction' ||
                            card.status === 'Sell' ||
                            card.status === 'Auction' ? (
                              <>
                                <img src={card.image} alt='Denim Jeans' />
                              </>
                            ) : (
                              <img src={cart_card} alt='Denim Jeans' />
                            )}
                          </>
                        )}

                        <h1 className='cart-name'>Play Fun{index}</h1>
                        <h2 className='cart-details'>
                          breed count : {card.token_id}
                        </h2>

                        <p className='cart-details'>Price</p>
                        <div className='d-flex'>
                          <img className='eth-image' src={eth} />
                          <h1 className='cart-price'>{card.price}</h1>
                        </div>
                      </div>
                      {card.status === 'Sold' &&
                      card.owner !==
                        web3.utils.toChecksumAddress(walletAddr) ? (
                        <div className='sold-out'>
                          <img width={50} height={50} src={soldOut} />
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              {/* {showbuy.length > 0 && showbuy.map((card, index) => {
                return (
                  <div key={index} className="card" onClick={() => details_page(card)}>
                    <div className="card-section">
                      <img src={cart_card} alt="Denim Jeans" />
                      <h1 className="cart-name">Viking #000{card}</h1>
                      <p className="cart-details">Breed Count : 2</p>
                      <p className="cart-details">Price</p>
                      <div className="d-flex">
                        <img className="eth-image" src={eth} />
                        <h1 className="cart-price">0.02</h1>
                        <br/>
                      </div>
                    </div>
                    <div className="sold-out">
                      <img width={50} height={50} src={soldOut} />
                    </div>
                  </div>
                );
              })} */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Market_place;
