import Header from "../Components/Header";
import "../Styles/product.css";
import cart_card from "./check.gif";
import eth from "../assets/images/eth.png";
import { ethers } from "ethers";
import Web3 from "web3";
import * as React from "react";
import { useEffect } from "react";
import nftabi from "./nft.json";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { useFormik } from "formik";
import * as yup from 'yup';
import { useState, useRef } from "react";
import "../Styles/sellColletect.css";
import cart_items from "../assets/images/cart_card.png";
import Swal from "sweetalert2";
import swal from "sweetalert";
import marketabi from "./marketabi.json";
// import Select from "react-select";
let web3 = new Web3(window.ethereum);

function SellCollectedNft() {
  const [show, setShow] = useState(false);
  let isLogedin = localStorage.getItem("isLogedin");
  let token = localStorage.getItem("token");
  const [imageuri, setimage] = React.useState(cart_card);
  const [nftdata, setnftdata] = React.useState([]);

  const [amount, setamount] = React.useState();
  const [duration, setduration] = React.useState();
  const Nft = new web3.eth.Contract(
    nftabi,
    "0xd02F92D5f4Cd365070e6B51B40d605c7912d003a"
  );
  const navigate = useNavigate();
  const success = (value) => {
    toast.success(value, { theme: "colored" });
  };
  const faild = (value) => {
    toast.error(value, { theme: "colored" });
  };
  const createhandleShow = () => setShow(true);
  const createhandleClose = () => setShow(false);
  const [_acc, setAcc] = React.useState();
  const [balance, setBal] = React.useState();
  const [minted, setminted] = React.useState(false);
  const [sellonly, setsellonly] = React.useState(false);
  const [auctiononly, setauctiononly] = React.useState(false);

  let location = window.location.href;
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmpassword: '',
      terms: false,
      notify: false
    },
    validationSchema: yup.object({
      email: yup.string().email("Email must be valid email").required("Email is required"),
      password: yup.string().required("Password is required").min(8, 'Password is too short - should be 8 chars minimum.').matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
      confirmpassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
      terms: yup.boolean().oneOf([true], "You must accept the pricing policy terms and conditions"),
      notify: yup.boolean().oneOf([true], "You must accept the pricing policy terms and conditions"),
    }),
    onSubmit: (userinputdata) => {
      let registerdate = {
        email: userinputdata.email,
        password: userinputdata.password,
        confirm_password: userinputdata.confirmpassword,
      }

      axios.post(`${process.env.REACT_APP_Backend_url}/auth/register`, registerdate).then(res => {
        if (res.data.message === "Registration done successfully") {
          success(res.data.message);
          createhandleClose();
        } else {
          faild(res.data.message);
        }
      });

    }
  });
  const backUrl = () => {
    navigate(-1);
  }

  const SellOnly = () => {setsellonly(!sellonly)}
  const Auctionly = () => {setauctiononly(!auctiononly)}

  var url = new URL(location);
  var id = url.searchParams.get("id");
  console.log(id);
  useEffect(() => {
    async function dataaa() {
      await loadWeb3();
      let accounts = await window.web3.eth.getAccounts();
      axios
        .get(
          `${process.env.REACT_APP_Backend_url}/auth/get-newbuynft?token_id=${id}`,
          {
            headers: { "x-access-token": `${token}` },
          }
        )
        .then(async (res) => {
          console.log(res.data?.data?.Nft_data[0]);
          if (res.data?.data?.Nft_data[0]?.image) {
            setimage(res.data?.data.Nft_data[0]?.image);
            setnftdata(res.data?.data.Nft_data[0]);
            setminted(true);
          }
        });
      setAcc(accounts[0]);
      console.log(accounts[0]);
      let bal = await web3.eth.getBalance(accounts[0]);
      const etherValue = Web3.utils.fromWei(bal, 'ether');
      setBal(etherValue);
      console.log(etherValue);
    }
    dataaa();
  }, []);

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  };
  const sellvalidation = useFormik({
    initialValues: {
      amount: '',
      date: '',
      privacy:false
    },
    validationSchema: yup.object({
      amount: yup.string().required("Amount is Required"),
      date: yup.date().default(() => new Date()),
      privacy : yup.boolean().required("Please check privacy and policy")
    }),
    onSubmit: async (userinputdata) => {
      
      if(sellonly || auctiononly){
        
          sellNft(userinputdata.amount);
        
      }else{
        faild("Please Select Market Sale or auction")
      }
     
      
     
    } 
  });

  const sellNft = async (amount) => {
    console.log(sellonly);
    console.log(auctiononly);
    let ownercheck = await Nft.methods.ownerOf(id).call();
    console.log("ownercheck-",ownercheck);

// if (ownercheck === {nftowner}){

    if(sellonly && auctiononly) {

        await loadWeb3();
        const accounts = await window.web3.eth.getAccounts();
    
        console.log(accounts[0]);
        const cryptoContract = new web3.eth.Contract(
          marketabi,
          "0x3db640C2fE2E9399d366a6CdBB935cb07B46f745"
        );
        console.log(cryptoContract.methods);

        try {
  
          const price1 = web3.utils.toWei(amount.toString(), "ether");
          console.log(price1);
          console.log(id);
          console.log("d", duration);
          var date = new Date(duration); // some mock date
          var auctionTimeInSeconds = Math.floor(date / 1000);
          console.log("current seconds", auctionTimeInSeconds);
  
         await cryptoContract.methods
            .createSellAuction (
              "0xd02F92D5f4Cd365070e6B51B40d605c7912d003a",
              String(id),
              String(price1),
              auctionTimeInSeconds
              
            )
            .send({ from: accounts[0] , gas: 1000000})
            .on("transactionHash", async (res) => {
              console.log(res);
              let marketId = await cryptoContract.methods
                .getCurrentMarketId()
                .call();
              console.log("marketid-",marketId);
              if (marketId) {

              let postdata = {
                token_id:id,
                sell_price: amount,  
                wallet_address: accounts[0],
                auction_duration:duration,
                marketId: marketId,
                sellAuction_transaction_id: res
              };
              console.log("post-",postdata);
              axios
              .post(`${process.env.REACT_APP_Backend_url}/auth/createSellAuctionNft`, postdata, {
                headers: { "x-access-token": `${token}` },
              })
              .then(async (res) => {
                if (res.data.message === "sale for sell and auction started successfully") {
                  // navigate("/Shop")
                  success(res.data.message);
                }
              });
             await swal("Success", "Sale and Auction Started in Marketplace " + res, "success").then(
                setTimeout(function () {
                  window.location.reload();
                }, 2000)
              );
            }else{
              faild("Transaction faild");
            }
            });
        } catch (err) {
          console.log(err);
        }


    } else if (sellonly) {

        await loadWeb3();
        const accounts = await window.web3.eth.getAccounts();
    
        console.log(accounts[0]);
        const cryptoContract = new web3.eth.Contract(
          marketabi,
          `${process.env.REACT_APP_Marketplace_Address}`
    
        );
        console.log(cryptoContract);
    
        try {
    
          const price1 = web3.utils.toWei(amount.toString(), "ether");
          console.log(price1);
          console.log(id);
    
          await cryptoContract.methods
            .createMarketItem(
              `${process.env.REACT_APP_Nft_address}`,
              String(id),
              String(price1)
            )
            .send({ from: accounts[0], gas: 1000000 })
            .on("transactionHash", async (res) => {
              console.log(res);
              let marketId = await cryptoContract.methods
                .getCurrentMarketId()
                .call();
              if (marketId) {
                let postdata = {
                  token_id: id,
                  sell_price: amount,
                  wallet_address: accounts[0],
                  marketId: marketId,
                  sell_transaction_id: res
                };

                axios
                  .post(`${process.env.REACT_APP_Backend_url}/auth/sellNft`, postdata, {
                    headers: { "x-access-token": `${token}` },
                  })
                  .then(async (res) => {
                    if (res.data.message === "Listed for sale successfully") {
                      // navigate("/Shop")
                      success(res.data.message);
                    }
                  });
                await swal("Success", "Sale Started in Marketplace " + res, "success").then(
                  setTimeout(function () {
                    window.location.reload();
                  }, 2000)
                );
              }else{
                faild("Transaction faild");
              }
    
            });
        } catch (err) {
          console.log(err);
        }

    }else if (auctiononly){

      await loadWeb3();
        const accounts = await window.web3.eth.getAccounts();
    
        console.log(accounts[0]);
        const cryptoContract = new web3.eth.Contract(
          marketabi,
          "0x3db640C2fE2E9399d366a6CdBB935cb07B46f745"
        );
        console.log(cryptoContract.methods);
  
        try {
  
          const price1 = web3.utils.toWei(amount.toString(), "ether");
          console.log(price1);
          console.log(id);
          console.log("d", duration);
          var date1 = new Date(duration); // some mock date
          var auctionTimeInSeconds1 = Math.floor(date1 / 1000);
          console.log("current seconds", auctionTimeInSeconds1);
  
         await cryptoContract.methods
            .createTokenAuction (
              "0xd02F92D5f4Cd365070e6B51B40d605c7912d003a",
              String(id),
              String(price1),
              auctionTimeInSeconds1
              
            )
            .send({ from: accounts[0] , gas: 1000000})
            .on("transactionHash", async (res) => {
              console.log(res);
              let marketId = await cryptoContract.methods
                .getCurrentMarketId()
                .call();
              console.log("marketid-",marketId);
              if (marketId) {
            
              let postdata = {
                token_id:id,
                bid_amount_init: amount,  
                Auction_creator: accounts[0],
                auction_duration:duration,
                auction_transaction_id: res
              };
              console.log("post-",postdata);
              axios
              .post(`${process.env.REACT_APP_Backend_url}/auth/createAuctionNft`, postdata, {
                headers: { "x-access-token": `${token}` },
              })
              .then(async (res) => {
                if (res.data.message === "sale for sell and auction started successfully") {
                  // navigate("/Shop")
                  success(res.data.message);
                }
              });

             await swal("Success", "Sale and Auction Started in Marketplace " + res, "success").then(
                setTimeout(function () {
                  window.location.reload();
                }, 2000)
              );
            }else{
              faild("Transaction faild");
            }
            });
            
        } catch (err) {
          console.log(err);
        }


    }
  // }
  // else{
  //   faild("You Are Not Owner");
  // }
  };

  const getamount = (e) => {
    setamount(e.target.value);
  }

  const getduration = (e) => {
    setduration(e.target.value);
  }

  return (
    <>
      <Header active="product" />
      <main>
        <div className="container padding-top30px sell-collected-nft">
          <div className="row">
            <div className="col-lg-2">
              <button className="back-button" onClick={() => backUrl()} > <i className="fa fa-arrow-left"></i> Back </button>
            </div>

            <div className="col-lg-10 offset-lg-1">
              <div className="">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="container">
                      <div className="row" >
                        <div className="col-lg-12 ">

                          {/* <div className="horizontal-line"> */}
                          <form className="sell-collected-form d-grid gap-3" onSubmit={sellvalidation.handleSubmit}>
                            <h2 className="sell-collected-nft-title">List Item for Sale</h2>
                            <div className="row">
                              <div className="col-lg-6">
                                <h3 className="place-subtitle">Price</h3>
                              </div>
                              <div className="col-lg-6">

                                <h3 className="place-subtitle balance text-right">Balance: {balance}</h3>

                              </div>
                            </div>
                            <div className="row" >
                              <div className="col-lg-4 no-padding">
                                <select className="select-currency">
                                  {/* <option><img src={eth} />ETH</option> */}
                                  <option><img src={eth} />BNB</option>
                                </select>
                              </div>
                              <div className="col-lg-8 no-padding">
                                <div className="place-bid ml-1">
                                  <input className="input-field" onChange={sellvalidation.handleChange} type="text" placeholder="Amount" name="amount" />
                                  {sellvalidation.errors.amount ? (<div className='sellvalidatoin-error text-danger'>{sellvalidation.errors.amount} </div>) : null}

                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6 ">
                                <h3 className="place-subtitle">Duration</h3>
                              </div>

                            </div>
                            <div className="row mt-10 " >
                              {/* <div className="col-lg-4 no-padding">
                                <select className="filter-select">
                                  <option>1 Days</option>
                                  <option>2 Days</option>
                                  <option>3 Days</option>
                                  <option>4 Days</option>
                                  <option>5 Days</option>
                                </select>
                              </div> */}
                              <div className="col-lg-8 no-padding">
                                <div className="place-bid ">
                                  <input className="input-field" onChange={(e) => getduration(e)} type="date" placeholder="Date" name="date" />
                                  {/* {sellvalidation.errors.date ? (<div className='sellvalidatoin-error text-danger'>{sellvalidation.errors.amount} </div>) : null} */}

                                </div>
                              </div>
                            </div>
                            <div class="mb-3 form-check">
                              <input type="checkbox" onClick={() => SellOnly()} class="form-check-input" id="exampleCheck1" />
                              <label class="form-check-label" for="exampleCheck1">Allow Buyers to buy DIRECTLY</label>
                            </div>
                            <div class="mb-3 form-check">
                              <input type="checkbox" onClick={() => Auctionly()} class="form-check-input" id="exampleCheck1" />
                              <label class="form-check-label" for="exampleCheck1">Allow Buyers to make BID</label>
                            </div>
                            <div class="mb-3 form-check">
                              <input type="checkbox" class="form-check-input" id="exampleCheck1" checked disabled />
                              <label class="form-check-label" for="exampleCheck1">By checking this box, you accept the <span> privacy policy </span>
                                and <span>Terms of Service.</span></label>
                            </div>
                            {/* <center>
                              <button className="btn btn-primary place-bid" type="button">Place-bid</button>
                            </center> */}
                          <hr style={{ border: "1px solid #5D5D5D" }} />
                          <div className="fees">

                            <h4 className="fees-heading">Fees</h4>
                            <div className="d-flex justify-content-between">
                              <h4 className="service-heading">Service Fee</h4>
                              <h4 className="service-heading">10%</h4>
                            </div>

                          </div>
                          <center>
                            <button className="btn btn-primary sell-collected mt-5" type="submit" onClick={() => sellNft()}>Post listing</button>
                          </center>
                          </form>
                        
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card" >
                      <img src={imageuri} alt="Denim Jeans" />
                      {console.log(nftdata)}
                      <h1 className="cart-name">Name : {nftdata.name}</h1>
                      <p className="cart-details">TokenID : {nftdata.token_id} </p>
                      <p className="cart-details">Price</p>
                      <div className="d-flex">
                        <img className="eth-image" src={eth} />
                        <h1 className="cart-price">0.02</h1>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="row">
                  <div className="col-lg-8">

                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </main>
      <Modal
        show={show}
        onHide={createhandleClose}
        dialogClassName="modal-50w"
        aria-labelledby="example-custom-modal-styling-title"
      >

        <Modal.Body>
          <div className="container-fluid">
            <div className="row" >
              <div className="col-lg-12 ">
                <div className="text-right">
                  <button className="modal-close-button" onClick={createhandleClose}></button>
                </div>
                {/* <div className="horizontal-line"> */}
                <form className="register-form d-grid gap-3" onSubmit={formik.handleSubmit}>
                  <h2 className="register-title">Place Bid</h2>
                  <div className="row">
                    <div className="col-lg-6">
                      <h3 className="place-subtitle">Bidding Amount</h3>
                    </div>
                    <div className="col-lg-6">
                      <h3 className="place-subtitle balance text-right">Balance: 0.00000ETH</h3>
                    </div>
                  </div>
                  <div className="row" >
                    <div className="col-lg-4 ">
                      <select className="filter-select">
                        {/* <option><img src={eth} />ETH</option> */}
                        <option><img src={eth} />BNB</option>
                      </select>
                    </div>
                    <div className="col-lg-8">
                      <div className="place-bid ml-1">
                        <input className="input-field" type="email" placeholder="Amount" name="email" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <h3 className="place-subtitle">Bid expiration</h3>
                    </div>
                    {/* <div className="col-lg-6">                    
                      <h3 className="place-subtitle balance text-right">Balance: 0.00000ETH</h3>
                    </div> */}
                  </div>
                  <div className="row mt-10" >
                    <div className="col-lg-4">
                      <select className="filter-select">
                        <option>1 Days</option>
                        <option>2 Days</option>
                        <option>3 Days</option>
                        <option>4 Days</option>
                        <option>5 Days</option>
                      </select>
                    </div>
                    <div className="col-lg-8">
                      <div className="place-bid ">
                        <input className="input-field" type="date" placeholder="Amount" name="date" />
                      </div>
                    </div>
                  </div>
                  <center>
                    <button className="btn btn-primary place-bid" type="button">Place-bid</button>
                  </center>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default SellCollectedNft;