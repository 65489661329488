import Header from "../Components/Header";
import "../Styles/product.css";
import cart_card from "./check.gif";
import eth from "../assets/images/eth.png";
import { ethers } from "ethers";
import Web3 from "web3";
import * as React from "react";
import { useEffect } from "react";
import nftabi from "./nft.json";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { useFormik } from "formik";
import * as yup from 'yup';
import { useState, useRef } from "react";
import profile_large_image from "../assets/images/profile-large-image.png";
import share from "../assets/images/share.png";
import settings from "../assets/images/settings.png";
import cart_items from "../assets/images/cart_card.png";
import '../Styles/settings.css';
import sortby from "../../src/assets/images/sortby.png";
import user_default from '../assets/images/user_default.png';
import logoutimg from '../assets/images/logut.png';
import Profile from '../assets/images/profile.png';
import Notification from '../assets/images/Notification.png';
import Doller from '../assets/images/Doller.png';
import Support from '../assets/images/Support.png';
import Payments from '../assets/images/Payments.png';

import favourite from '../assets/images/favourite.png';
// import Select from "react-select";
let web3 = new Web3(window.ethereum);

function Settings() {
  const [show, setShow] = useState(false);
  let isLogedin = localStorage.getItem("isLogedin");
  let token = localStorage.getItem("token");
  const [imageuri, setimage] = React.useState(cart_card);
  const Nft = new web3.eth.Contract(
    nftabi,
    process.env.REACT_APP_Contract_Address
  );
  const navigate = useNavigate();
  const success = (value) => {
    toast.success(value, { theme: "colored" });
  };
  const faild = (value) => {
    toast.error(value, { theme: "colored" });
  };
  const createhandleShow = () => setShow(true);
  const createhandleClose = () => setShow(false);
  const [_acc, setAcc] = React.useState();
  const [minted, setminted] = React.useState(false);
  const show_collected = () => {
    navigate("/collected-nft");
  }
  let location = window.location.href;
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmpassword: '',
      terms: false,
      notify: false
    },
    validationSchema: yup.object({
      email: yup.string().email("Email must be valid email").required("Email is required"),
      password: yup.string().required("Password is required").min(8, 'Password is too short - should be 8 chars minimum.').matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
      confirmpassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
      terms: yup.boolean().oneOf([true], "You must accept the pricing policy terms and conditions"),
      notify: yup.boolean().oneOf([true], "You must accept the pricing policy terms and conditions"),
    }),
    onSubmit: (userinputdata) => {
      let registerdate = {
        email: userinputdata.email,
        password: userinputdata.password,
        confirm_password: userinputdata.confirmpassword,
      }

      axios.post(`${process.env.REACT_APP_Backend_url}/auth/register`, registerdate).then(res => {
        if (res.data.message === "Registration done successfully") {
          success(res.data.message);
          createhandleClose();
        } else {
          faild(res.data.message);
        }
      });

    }
  });
  var url = new URL(location);
  var id = url.searchParams.get("id");
  console.log(id);
  useEffect(() => {
    async function dataaa() {
      await loadWeb3();
      let accounts = await window.web3.eth.getAccounts();
      axios
        .get(
          `${process.env.REACT_APP_Backend_url}/auth/get-buynft?token_id=${id}`,
          {
            headers: { "x-access-token": `${token}` },
          }
        )
        .then(async (res) => {
          console.log(res.data?.data._userDetail[0]?.Nft_data[0]?.image);
          if (res.data?.data._userDetail[0]?.Nft_data[0]?.image) {
            setimage(res.data?.data._userDetail[0]?.Nft_data[0]?.image);
            setminted(true);
          }
        });
      setAcc(accounts[0]);
      console.log(accounts[0]);
    }
    dataaa();
  }, []);

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  };

  const buyNft = async () => {
    await loadWeb3();
    const accounts = await window.web3.eth.getAccounts();

    console.log(accounts[0]);

    try {
      let name = await Nft.methods.name().call();
      console.log(name);
      let price1 = "0.02";
      //   const price1 = ethers.utils.parseUnits(newPrice.toString(), "ether");
      console.log(id);

      await Nft.methods
        .BuyNFT(process.env.REACT_APP_Nft_address, accounts[0], id)
        .send({
          from: accounts[0],
          value: Web3.utils.toWei(price1, "ether"),
          gas: 100000,
        })
        .then(async (res) => {
          console.log(res);
          let tokenidaddress = await Nft.methods.ownerOf(id).call();
          console.log(tokenidaddress);
          let getUriBytokenid = await Nft.methods.tokenURI(id).call();
          console.log(getUriBytokenid);

          const response = await fetch(getUriBytokenid);

          if (!response.ok) throw new Error(response.statusText);

          const json = await response.json();
          console.log(json);
          let login_data = {
            image: json.image,
            description: json.image,
            token_id: id,
          };
          axios
            .post(`${process.env.REACT_APP_Backend_url}/auth/buynft`, login_data, {
              headers: { "x-access-token": `${token}` },
            })
            .then(async (res) => {
              if (res.data.message === "mint successfully") {
                success(res.data.message);
              }
            });
          setimage(json.image);
          console.log(json.image);
        });
    } catch (err) {
      console.log(err);
    }
    // let gettokenidByaddress = await Nft.methods.gettokenidByaddress("0x9bbc39819A0a1cE456Bd0fE59A37d2C60Ab8143F").call();


  };
  const settings = () => {
    navigate("/settings");
  }
  return (
    <>
      <Header active="" />
      <main>
        <div className="container-fluid no-padding settings">
          <div className="row">
            <div className="col-lg-12">

              <div class="sidenav">
                <h3 className="settings-title">Settings</h3>
                <a className="active" href="#"><img src={Profile} />Profile</a>
                <a href="#"><img src={Notification} />Notification</a>
                <a href="#"><img src={Payments} />Payments</a>
                <a href="#"><img src={Support} />Support</a>
                <a href="#"><img src={Doller} />Earnings</a>
              </div>

              <div class="main">
                <div className="row">
                  <div className="col-lg-12">
                    <h4 className="setting-profile-text">Profile Details</h4>

                    <div className="row">
                      <div className="col-lg-6">
                        <form>
                          <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Profile Name</label>
                            <input type="text" class="form-control" id="exampleInputEmail1" value="Name" aria-describedby="emailHelp" />
                          </div>
                          <div class="mb-3">
                            <label for="exampleInputPassword1" class="form-label">Email Address</label>
                            <input type="email" class="form-control" value="example@mail.com" id="exampleInputPassword1" />
                          </div>
                          <div class="mb-3">
                            <label for="exampleInputPassword1" class="form-label">Wallet Address</label>
                            <input type="text" class="form-control" value="0x5B0E1D49eeC03c4E62a04dd85e6872e12eEDF611" id="exampleInputPassword1" />
                          </div>
                          <button type="submit" class="btn btn-primary">Save</button>
                        </form>
                      </div>
                      <div className="col-lg-6 profile-pic-section">
                      <h4 className="setting-profilepic-text">Profile Details</h4>
                        <div className="profile-image">
                          
                          <img src={profile_large_image} />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Modal
        show={show}
        onHide={createhandleClose}
        dialogClassName="modal-50w"
        aria-labelledby="example-custom-modal-styling-title"
      >

        <Modal.Body>
          <div className="container-fluid">
            <div className="row" >
              <div className="col-lg-12 ">
                <div className="text-right">
                  <button className="modal-close-button" onClick={createhandleClose}></button>
                </div>
                {/* <div className="horizontal-line"> */}
                <form className="register-form d-grid gap-3" onSubmit={formik.handleSubmit}>
                  <h2 className="register-title">Place Bid</h2>
                  <div className="row">
                    <div className="col-lg-6">
                      <h3 className="place-subtitle">Bidding Amount</h3>
                    </div>
                    <div className="col-lg-6">
                      <h3 className="place-subtitle balance text-right">Balance: 0.00000ETH</h3>
                    </div>
                  </div>
                  <div className="row" >
                    <div className="col-lg-4 ">
                      <select className="filter-select">
                        <option><img src={eth} />ETH</option>
                        <option><img src={eth} />BNB</option>
                      </select>
                    </div>
                    <div className="col-lg-8">
                      <div className="place-bid ml-1">
                        <input className="input-field" type="email" placeholder="Amount" name="email" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <h3 className="place-subtitle">Bid expiration</h3>
                    </div>
                    {/* <div className="col-lg-6">                    
                      <h3 className="place-subtitle balance text-right">Balance: 0.00000ETH</h3>
                    </div> */}
                  </div>
                  <div className="row mt-10" >
                    <div className="col-lg-4">
                      <select className="filter-select">
                        <option>1 Days</option>
                        <option>2 Days</option>
                        <option>3 Days</option>
                        <option>4 Days</option>
                        <option>5 Days</option>
                      </select>
                    </div>
                    <div className="col-lg-8">
                      <div className="place-bid ">
                        <input className="input-field" type="date" placeholder="Amount" name="date" />
                      </div>
                    </div>
                  </div>
                  <center>
                    <button className="btn btn-primary place-bid" type="button">Place-bid</button>
                  </center>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Settings;
