import Header from "../Components/Header";
import "../Styles/product.css";
import cart_card from "./check.gif";
import eth from "../assets/images/eth.png";
import { ethers } from "ethers";
import Web3 from "web3";
import * as React from "react";
import { useEffect } from "react";
import nftabi from "./nft.json";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { useFormik } from "formik";
import * as yup from 'yup';
import { useState, useRef } from "react";
import "../Styles/collected.css";
import price_histroy from "../assets/images/price_histroy.png";
import listing from "../assets/images/listing.png";
import Offers from "../assets/images/offers.png";
import history from "../assets/images/colleted-history.png";
import cart_items from "../assets/images/cart_card.png";
import Swal from "sweetalert2";
import swal from "sweetalert";
import marketabi from "./marketabi.json";

// import Select from "react-select";
let web3 = new Web3(window.ethereum);

function CollectedNft() {
  const [show, setShow] = useState(false);
  const [showAccept, setShowAccept] = useState(false);
  let isLogedin = localStorage.getItem("isLogedin");
  let token = localStorage.getItem("token");
  const [imageuri, setimage] = React.useState(cart_card);
  const [nftdata, setnftdata] = React.useState({});

  const Nft = new web3.eth.Contract(
    nftabi,
    process.env.REACT_APP_Contract_Address
  );
  const navigate = useNavigate();
  const success = (value) => {
    toast.success(value, { theme: "colored" });
  };
  const faild = (value) => {
    toast.error(value, { theme: "colored" });
  };
  const createhandleShow = () => setShow(true);
  const createhandleClose = () => setShow(false);
  const createAccepthandleShow = () => setShowAccept(true);
  const createAccepthandleClose = () => setShowAccept(false);
  const [_acc, setAcc] = React.useState();
  const [minted, setminted] = React.useState(false);
  let location = window.location.href;
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmpassword: '',
      terms: false,
      notify: false
    },
    validationSchema: yup.object({
      email: yup.string().email("Email must be valid email").required("Email is required"),
      password: yup.string().required("Password is required").min(8, 'Password is too short - should be 8 chars minimum.').matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
      confirmpassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
      terms: yup.boolean().oneOf([true], "You must accept the pricing policy terms and conditions"),
      notify: yup.boolean().oneOf([true], "You must accept the pricing policy terms and conditions"),
    }),
    onSubmit: (userinputdata) => {
      let registerdate = {
        email: userinputdata.email,
        password: userinputdata.password,
        confirm_password: userinputdata.confirmpassword,
      }

      axios.post(`${process.env.REACT_APP_Backend_url}/auth/register`, registerdate).then(res => {
        if (res.data.message === "Registration done successfully") {
          success(res.data.message);
          createhandleClose();
        } else {
          faild(res.data.message);
        }
      });

    }
  });
  var url = new URL(location);
  var id = url.searchParams.get("id");
  console.log(id);
  useEffect(() => {
    async function dataaa() {
      await loadWeb3();
      let accounts = await window.web3.eth.getAccounts();
      axios
        .get(
          `${process.env.REACT_APP_Backend_url}/auth/get-newbuynft?token_id=${id}`,
          {
            headers: { "x-access-token": `${token}` },
          }
        )
        .then(async (res) => {
          console.log(res.data?.data?.Nft_data[0]);
          if (res.data?.data?.Nft_data[0]?.image) {
            setnftdata(res.data?.data?.Nft_data[0]);
            setminted(true);
          }
        });
      setAcc(accounts[0]);
      console.log(accounts[0]);
    }
    dataaa();
  }, []);

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  };




  const sellCollectedNft = () => {
    navigate(`/sell-collected-nft?id=${id}`);
    // navigate("/sell-collected-nft")
  }
  const backUrl = () => {
    navigate(-1);
  }
  return (
    <>
      <Header active="product" />
      <main>
        <div className="container padding-top30px">
          <div className="row">
            <div className="col-lg-12">
              <div className="col-lg-2">
                <button className="back-button" onClick={() => backUrl()} > <i className="fa fa-arrow-left"></i> Back </button>
              </div>
              <div className="colleted-details-page">
                <div className="row">
                  <div className="col-lg-4 product-section">
                    <div className="row">
                      <div className="col-lg-8 col-md-8">
                        <div className="row">
                          {/* <div className="col-lg-6 col-md-6">
                            <div className="d-flex views">
                              <i className="fa fa-eye" aria-hidden="true"></i>
                              <p>13 Views</p>
                            </div>
                          </div> */}
                          {/* <div className="col-lg-6 col-md-6">
                            <div className="d-flex views">
                              <i className="fa fa-heart"></i>
                              <p>2 Likes</p>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4">
                        {/* <div className="d-flex views">
                          <i className="fa fa-share-alt"></i>
                          <p>Share</p>
                        </div> */}
                      </div>
                    </div>
                    <img  className="product-page-img mt-3" src={nftdata.image} />
                  </div>
                  <div className="col-lg-8 padding-start">
                    <h2 className="nft-name">{nftdata.name}</h2>
                    <h3 className="owned-status">Owned by <span>you</span></h3>
                    <div className="d-flex justify-content-start">
                      <button className="but-nft-button" onClick={() => sellCollectedNft()}>
                        Sell
                      </button>
                      {/* <button className="but-nft-button" onClick={createhandleShow}>Transfer</button> */}
                    </div>
                    {/* <div class="accordion accordion-flush price-history" id="accordionFlushExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            <img width={20} height={20} src={price_histroy} />
                            Price History
                          </button>
                        </h2>
                        <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                          <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.</div>
                        </div>
                      </div>
                    </div> */}
                    <div className="price-section">

                      <div className="header-section d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <img width={20} height={20} src={price_histroy} />
                          <h3 className="price-section-header">Price History</h3>
                        </div>
                        <i className="fa fa-angle-up"></i>
                      </div>
                      <div className="price-box-list">
                        <p className="no-price" >No Price History</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 no-padding">
                    <div className="row">
                      <div className="over-view-border">
                        <div className="over-view-section">
                          <h3 className="product-description">Overview</h3>
                          <p className="product-description-details">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Molestie magna suspendisse posuere eget metus turpis.
                            Iaculis dolor tristique diam egestas vitae congue mauris
                            diam sem.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="accordion accordion-flush offer-price" id="accordionFlushExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="flush-headingofferone">
                            <button class="accordion-button collapsed " type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseofferone" aria-expanded="false" aria-controls="flush-collapseofferone">
                              <img width={20} height={20} className="ml-1" src={Offers} />
                              &nbsp;&nbsp;Details
                            </button>
                          </h2>
                          <div id="flush-collapseofferone" class="accordion-collapse collapse" aria-labelledby="flush-headingofferone" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="details-section">
                                      <div className="d-flex justify-content-between">
                                          <h5>Contract Address</h5>
                                          <h5 className="colored">0x9A6A7195F41cc..8b0D5a0</h5>
                                      </div>
                                      <div className="d-flex justify-content-between">
                                          <h5>Breed count</h5>
                                          <h5 className="colored">{nftdata.token_id}</h5>
                                      </div>
                                      <div className="d-flex justify-content-between">
                                          <h5>Token Standard</h5>
                                          <h5 className="gray">BNB</h5>
                                      </div>
                                      <div className="d-flex justify-content-between">
                                          <h5>Blockchain</h5>
                                          <h5 className="gray">BSC Chain</h5>
                                      </div>
                                      <div className="d-flex justify-content-between">
                                          <h5>Metadata</h5>
                                          <h5 className="colored">Frozen</h5>
                                      </div>
                                      <div className="d-flex justify-content-between">
                                          <h5>Creator Earnings</h5>
                                          <h5 className="gray">2.5%</h5>
                                      </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="mt-2">
                      <div class="accordion accordion-flush price-history" id="accordionFlushExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="flush-headingOne">
                            <button class="accordion-button collapsed " type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                              <img width={20} height={20} className="ml-1" src={listing} />
                              &nbsp;&nbsp;Listings
                            </button>
                          </h2>
                          <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                              <table cellPadding="0">
                                <thead>
                                  <tr>
                                    <th>Price</th>
                                    <th>USD Price</th>

                                    <th>Expiration</th>
                                    <th>From</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div className="d-flex">
                                        <img width={20} height={20} src={eth} />
                                        <p>0.002</p>

                                      </div>
                                    </td>
                                    <td>
                                      $100
                                    </td>

                                    <td>In day</td>
                                    <td>Username</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <div class="accordion accordion-flush price-history" id="accordionFlushExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="flush-headingoffertwo ">
                            <button class="accordion-button collapsed " type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseoffertwo" aria-expanded="false" aria-controls="flush-collapseoffertwo">
                              <img width={20} height={20} className="ml-1" src={Offers} />
                              &nbsp;&nbsp;Offers
                            </button>
                          </h2>
                          <div id="flush-collapseoffertwo" class="accordion-collapse collapse" aria-labelledby="flush-headingoffertwo" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                              <table cellPadding="0">
                                <thead>
                                  <tr>
                                    <th>Price</th>
                                    <th>USD Price</th>
                                    <th></th>
                                    <th>Expiration</th>
                                    <th>From</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div className="d-flex">
                                        <img width={20} height={20} src={eth} />
                                        <p>0.002</p>

                                      </div>
                                    </td>
                                    <td>
                                      $100
                                    </td>
                                    <td>
                                      <button className="accept-button" onClick={() => createhandleShow()} >Accept&nbsp;<i className="fa fa-angle-right"></i></button>
                                    </td>
                                    <td>In day</td>
                                    <td>Username</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-lg-4 no-padding">
                    <div className="listing-border">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <img width={20} height={20} src={Offers} />
                          <h3 className="price-section-header">Listings</h3>
                        </div>
                        <i className="fa fa-angle-down"></i>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-lg-12 no-padding">
                    <div className="price-history-section">
                      <div className="header-section d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <img width={20} height={20} src={history} />
                          <h3 className="price-section-header">Price History</h3>
                        </div>
                        <i className="fa fa-angle-up"></i>
                      </div>
                      <div className="price-box-list">
                        <table cellPadding="0">
                          <thead>
                            <tr>
                              <th>Event</th>
                              <th>Price</th>
                              <th>From</th>
                              <th>To</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Minted</td>
                              <td></td>
                              <td>Null</td>
                              <td>You</td>
                              <td>10:00AM
                                Aug 23, 2022</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <center>
                  <button className="view-colletion">View Collection</button>
                </center>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Modal
        show={show}
        onHide={createhandleClose}
        dialogClassName="modal-50w accept-place-bid"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="container-fluid">
            <div className="row" >
              <div className="col-lg-12 ">
                <div className="text-right">
                  <button className="modal-close-button" onClick={createhandleClose}></button>
                </div>
                {/* <div className="horizontal-line"> */}
                <form className="register-form d-grid gap-3" onSubmit={formik.handleSubmit}>
                  <h2 className="accept-bid-title">Accept Offer</h2>
                  <div className="row">
                    <div className="col-lg-6">
                      <h3 className="accetp-place-subtitle">Item</h3>
                      <div className="d-flex justify-content-around">
                        <img width={90} height={100} src={cart_items} />
                        <div>
                          <h3 className="accetp-place-subtitle">Viking #0001</h3>
                          <h4 className="accept-own-place-subtitle">Owned by <span>you</span></h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <h3 className="accetp-place-subtitle-subtotal">Subtotal</h3>
                      <br />
                      <div className="d-flex float-end">
                        <img width={25} height={25} src={eth} />
                        <h3 className="accetp-place-subtitle ">0.002</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <br />
                        <br />
                        <br />
                        <div className="d-flex justify-content-between">
                          <h3 className="accetp-place-subtitle">Fees</h3>
                          {/* <h3 className="accetp-place-subtitle">0.002</h3> */}
                        </div>
                        <br />
                        <div className="d-flex justify-content-between">
                          <h2 className="accept-fee-place-subtitle">Marketplace fee</h2>
                          <h2 className="accept-fee-place-subtitle">2.5%</h2>
                        </div>
                        <br />
                        <div className="d-flex justify-content-between">
                          <h2 className="accept-fee-place-subtitle">Creator Royalty</h2>
                          <h2 className="accept-fee-place-subtitle">10%</h2>
                        </div>
                        <br />
                        <div className="d-flex justify-content-between">
                          <h3 className="accetp-place-subtitle">Total Earnings</h3>
                          <div className="d-flex">
                            <img width={25} height={25} src={eth} />
                            <h2 className="accept-fee-place-subtitle">0.001975</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <center>
                    <button className="btn btn-primary accept-place-bid" onClick={() => {
                      createhandleClose();
                      createAccepthandleShow();
                    }} type="button">Accept</button>
                  </center>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showAccept}
        onHide={createAccepthandleShow}
        dialogClassName="modal-50w accept-place-bid"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="container-fluid">
            <div className="row" >
              <div className="col-lg-12 ">
                <div className="text-right">
                  <button className="modal-close-button" onClick={createAccepthandleClose}></button>
                </div>
                {/* <div className="horizontal-line"> */}
                <form className="register-form d-grid gap-3" onSubmit={formik.handleSubmit}>
                  <h2 className="accept-bid-title">Your sale is processing!</h2>
                  <h3 className="transaction-accept-bid-title">Success!You just sold Ether. It will be confirmed on blockchain Shortly.</h3>
                  <div className="row">
                    <div className="col-lg-12">
                      <center>
                        <img width={150} height={150} src={cart_items} />
                      </center>
                    </div>

                    <div className="col-lg-8 offset-lg-2">
                      <div className="transfered-status">
                        <br />
                        <br />
                        <table cellPadding="0">
                          <thead>
                            <tr>
                              <th>Status</th>
                              <th>Transaction Hash</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Processing</td>
                              <td className="transaction-address">0x922a...20ffd</td>
                            </tr>
                          </tbody>
                        </table>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default CollectedNft;
